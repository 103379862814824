import React, { Component, useEffect, useState, useLayoutEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import addRosterIcon from "Assets/RosterModule/plus.png";
import rosterIcon from "Assets/ClassModule/roster.png";
import tagIcon from "Assets/ClassModule/tag.png";
import UpgradePopUp from "../../upgradePopUp/upgradePopUp";
import standardIcon from "Assets/ClassModule/standard.png";
import back from "Assets/CommonComponent/back.png";
import iconSearch from "Assets/RosterModule/icon-search.png";
import cardList from "Assets/ClassModule/cardList.png";
import userShareIcon from "Assets/ClassModule/userShare.png";
import userRubrics from "Assets/ClassModule/userRubrics.svg";
import { createNotification } from "Config/notificationtoast";
import addAcademicImage from "Assets/AcademicModule/addAcademic.png";
import attendance from "Assets/AttendanceModule/attendance.svg";
import GroupIcon from "Assets/ClassModule/Group.png";
import GroupActiveIcon from "Assets/ClassModule/GroupActive.png";
import CommonTextField from "Components/CommonTextField/CommonTextField";
import cancelBlack from "Assets/RosterModule/cancelBlack.png";
import DivLoader from "Components/LoadingComponent/DivLoader";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import * as routes from "Router/RoutesURL";
import editIcon from "Assets/ClassModule/pencil-alt.png";
import deleteIcon2 from "Assets/RosterModule/deleteIcon.png";
import deleteIcon from "Assets/RosterModule/deleteIcon.png";
import iconNotes from "Assets/RosterModule/icon-notes.png";
import iconClass from "Assets/RosterModule/icon-class.png";
import iconLessonPlan from "Assets/RosterModule/icon-lesson.png";
import iconRubric from "Assets/RosterModule/icon-rubric.png";
import iconGradeBook from "Assets/RosterModule/icon-gradebook.png";
import iconShare from "Assets/RosterModule/icon-share.png";
import faicon from "Assets/ClassModule/FAIcon.png"
import {
  getClassList,
  addClass,
  editClass,
  deleteClass,
  editClassColorWheel,
  setClassSidebarOpen,
  searchClass,
} from "Actions/ClassAction/classAction";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import "./ClassDashboard.scss";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import { useRef } from "react";

const ClassDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const ref = useRef();
  const [filter, setFilter] = useState("both");
  const [academicYear, setAcademicYear] = useState("");
  const [academicYearId, setAcademicYearId] = useState("");
  const [class_id, setClassId] = useState("");
  const [ClassList, setClassList] = useState([]);
  const [ClassOverAllCount, setClassOverAllCount] = useState([]);
  const [deleteDiv, setDeleteDiv] = useState("");
  const [ClassName, setClassName] = useState("");
  const [ClassSection, setClassSection] = useState("");
  const [ClassGrade, setClassGrade] = useState("");
  const [AddClassType, setAddClassType] = useState("add");
  const [isAddClassSubmit, setIsAddClassSubmit] = useState(false);
  const [loadMoreCount, setLoadMoreCount] = useState(1);
  const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
  const [isWheelEdited, setIsWheelEdited] = useState(false);
  const [isWheelIndex, setIsWheelIndex] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [Total_Count, setTotalCount] = useState(0);
  const [classPopOverId, setClassPopOverId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedClassDataLoad, setSearchedClassDataLoad] = useState([])
  const [dataBeforeSearch, setDataBeforeSearch] = useState([])
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [numBoxes, setNumBoxes] = useState(0);
  const [studentDivHeight, setStudentDivHeight] = useState(0)

  // useOnClickOutside(ref, () => setClassPopOverId(null));
  useEffect(() => {
    sessionStorage.setItem("backpage2" , "smthelse")
    }, [])
  const noClassRef = useRef(null);
  const DataRef = useRef(null);

  let userLocalData = JSON.parse(sessionStorage.getItem("UserData"));
  const { data } = history?.location?.state;
  const filterSharByMe = [
    "material-icons",
    filter === "shared_to_me" ? "checkBoxClassfilter" : "unCheckBoxClassfilter",
  ];
  const filterBoth = [
    "material-icons",
    filter === "both" ? "checkBoxClassfilter" : "unCheckBoxClassfilter",
  ];
  const filterCreByMe = [
    "material-icons",
    filter === "created_by_me"
      ? "checkBoxClassfilter"
      : "unCheckBoxClassfilter",
  ];

  const loader = useSelector((state) => state.class.loader);
  const classData = useSelector((state) => state.class);

  const totalClassDataList = useSelector(
    (state) => state?.class?.class_Data?.data?.[0]
  );

  // const classCount = useSelector((state) => state && state.class && state.class.class_Data && state.class.class_Data.data && state.class.class_data.data.class_count)
  const searchedClassCount = useSelector(
    (state) => state?.class?.search_class?.data?.class_count
  );

  const searchedClassData = useSelector(
    (state) => state?.class?.search_class?.data?.class_data
  );
  const pattern = /^[a-zA-Z ]*$/;

  useEffect(() => {
    // const boxHeight = 80; // height of each box in pixels
    // const windowHeight = window.innerHeight;
    // const numBoxes = Math.floor(windowHeight / boxHeight);
    // setNumBoxes(numBoxes);
    const boxWidth = 319; // width of each box in pixels
    const boxHeight = 100; // height of each box in pixels
    const divWidth = noClassRef?.current?.offsetWidth;
    // const divHeight = window.innerHeight
    const divHeight = noClassRef?.current?.offsetHeight;
    const numBoxesWidth = Math.floor(divWidth / boxWidth);
    // const numBoxesWidth = 403
    const numBoxesHeight = Math.floor(divHeight / boxHeight);
    const numBoxes = numBoxesWidth * numBoxesHeight;
    setNumBoxes(numBoxes);
  }, []);

  useEffect(() => {
    if(numBoxes != 'NaN' && numBoxes > 0) {
      getClass()
    // const { data = "" } = history.location.state;
    // let classId = "";
    // if (data && data.class_data && data.class_data.length > 0) {
    //   classId = data.class_data[0].cc_class_id;
    // }
    // setAcademicYear(data.academic_year);
    // setAcademicYearId(data.academic_year_id);
    // setClassIdForGradebook(classId);
    // // this.setState({
    // //     academicYear: data.academic_year,
    // //     academic_year_id: data.academic_year_id,
    // //     classIdForGradebook: classId
    // // })
    // const value = {
    //   class_id: "",
    //   filter_by: filter,
    //   name_filter_by: nameFilter,
    //   page_no: loadMoreCount > 1 ? "full" : loadMoreCount,
    //   limit : numBoxes,
    //   // academic_year: data.year_ids,
    //   academic_year: data.academic_year_id,
    // };
    // dispatch(getRosterList(value));
  }
  },[numBoxes])

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = DataRef?.current;
    setStudentDivHeight(clientHeight)
    if(ClassList && ClassList.length != ClassOverAllCount?.[0]?.count) {
    if (parseInt(scrollTop ) + clientHeight == scrollHeight) {
      const { data = "" } = history.location.state;
         let pageNoData = loadMoreCount;
      pageNoData++;
      setLoadMoreCount(pageNoData)
      setLoadMoreCountStatus(true);
      setAcademicYear(data.academic_year);
      setAcademicYearId(data.academic_year_id);
      // this.setState({
      //     academicYear: data.academic_year,
      //     academicYearId: data.academic_year_id
      // })
      // const { filter, loadMoreCount } = this.state
      
      const value = {
        filter_by: filter,
        page_no: pageNoData,
        class_academic_year: data.year_ids,
        limit : numBoxes
      };
        dispatch(getClassList(value));
     
    }
  }
    //  else {
    //   setIsLoading(false)
    // }
  }

  useEffect(() => {
    if (searchedClassData) {
      setClassList(searchedClassData);
    //   setSearchedClassDataLoad(searchedClassData)
    }
  }, [searchedClassData]);

  // useEffect(() => {
  //   if(searchedClassCount) {
  //       let total = []
  //       total.push({count : searchedClassCount})
  //       setClassOverAllCount(total)
  //   }
  // },[searchedClassCount])

  // const classData = useSelector((state) => state?.class)

  // ---------Open Sidebar by Defult-------
  useEffect(() => {
    if (classData && classData.sideMenu) {
      window.$("#addClassModal").modal("show");
      dispatch(setClassSidebarOpen(false));
    }
  }, []);
  // ---------Open Sidebar by Defult-------

  useLayoutEffect(() => {
    if(numBoxes > 0) {
    getClass();
    }
  }, [filter]);

  //USEEFFETC ON CONDITIONS

  useEffect(() => {
    // ---------get Class WillReceiveProps-------
    if (!isWheelEdited) {
      if (classData.action === "GET_CLASS_LIST_SUCCESS") {
        if (classData.class_Data && classData.class_Data.status === 200) {
          if (classData?.class_Data?.data?.[0]?.length > 0) {
            // for (let value of classData?.class_Data?.data?.class_data) {
            //       }
            if (loadMoreCount > 1) {
              let getClassData = ClassList;
              for (let value of classData?.class_Data?.data?.[0]) {
                getClassData.push(value);
              }

              setClassList(getClassData);
              setClassOverAllCount(classData.class_Data.data[1]);
              setLoadMoreCountStatus(false);
              setDataBeforeSearch(getClassData)
              // this.setState({
              //     ClassList: getClassData,
              //     ClassOverAllCount: nextProps.classData.class_Data.data[1],
              //     loadMoreCountStatus: false
              // })
            } else {
              let data = classData?.class_Data?.data?.[0].map((item) => ({
                ...item,
                editOpen: false,
              }));
              setClassList(data);
              setDataBeforeSearch(data)
              setClassOverAllCount(classData?.class_Data?.data?.[1]);
              // this.setState({
              //     ClassList: nextProps.classData.class_Data.data[0],
              //     ClassOverAllCount: nextProps.classData.class_Data.data[1]
              // })
            }
          } else {
            setClassList([]);
            setClassOverAllCount([]);
            setDataBeforeSearch([])
            // this.setState({
            //     ClassList: [],
            //     ClassOverAllCount: []
            // })
          }
        }
      }
    }

    // if(classData?.search_class?.status == 200) {
    //   if (classData?.search_class?.data?.[0]?.length > 0) {
    //     // for (let value of classData?.search_class?.data?.search_class) {
    //     //       }
    //     if (loadMoreCount > 1) {
    //       let getClassData = ClassList;
    //       for (let value of classData?.search_class?.data?.[0]) {
    //         getClassData.push(value);
    //       }

    //       setClassList(getClassData);
    //       setClassOverAllCount(classData.search_class.data[1]);
    //       setLoadMoreCountStatus(false);
    //       setDataBeforeSearch(getClassData)
    //       // this.setState({
    //       //     ClassList: getClassData,
    //       //     ClassOverAllCount: nextProps.classData.search_class.data[1],
    //       //     loadMoreCountStatus: false
    //       // })
    //     } else {
    //       let data = classData?.search_class?.data?.[0].map((item) => ({
    //         ...item,
    //         editOpen: false,
    //       }));
    //       setClassList(data);
    //       setDataBeforeSearch(data)
    //       setClassOverAllCount(classData?.search_class?.data?.[1]);
    //       // this.setState({
    //       //     ClassList: nextProps.classData.search_class.data[0],
    //       //     ClassOverAllCount: nextProps.classData.search_class.data[1]
    //       // })
    //     }
    //   } else {
    //     setClassList([]);
    //     setClassOverAllCount([]);
    //     setDataBeforeSearch([])
    //     // this.setState({
    //     //     ClassList: [],
    //     //     ClassOverAllCount: []
    //     // })
    //   }
    // }

    if (classData.action === "ADD_CLASS_FAILURE" || "EDIT_CLASS_FAILURE") {
      if (
        classData.error &&
        (classData.error.message ===
          "Note limit has been exceeded. Please upgrade the plan" ||
          classData.error.message ===
            "Media limit has been exceeded. Please upgrade the plan" ||
          classData.error.message ===
            "Please update your subscription plan")
      ) {
        setShowPopUp(true);
        // this.setState({
        //     showPopUp: true
        // })
      }
    }

    // ---------get Class Color Wheel WillReceiveProps-------
    if (isWheelEdited) {
      if (classData.action === "EDIT_CLASS_COLOR_WHEEL_SUCCESS") {
        let classlist = ClassList;
        classlist[isWheelIndex].cc_class_color_wheel_display =
          classlist[isWheelIndex].cc_class_color_wheel_display === "yes"
            ? "no"
            : "yes";
        setClassList(classlist);
        setIsWheelEdited(false);
        setIsWheelIndex("");
        // this.setState({
        //     ClassList: ClassList,
        //     isWheelEdited: false,
        //     isWheelIndex: ''
        // })
      }
    }
    // ---------get Class Color Wheel WillReceiveProps--------

    // ---------get Class WillReceiveProps-------
  }, [classData]);

  // useEffect(() => {
  //   setClassOverAllCount(classCount)
  // },[classCount])

  useEffect(() => {
    if (ClassOverAllCount.length > 0) {
      setTotalCount(ClassOverAllCount[0].count);
    }
  }, [ClassOverAllCount]);

  // ---------getRoster by Filter-------
  const filterSelect = (value) => {
    setFilter(value);
    setLoadMoreCount(1);
    // getClass();
    // this.setState({
    //     filter: value,
    //     loadMoreCount: 1
    // }, () => {
    //     this.getClass();
    // })
  };
  // ---------getRoster by Filter-------

  // ---------BackTOHome-------
  const BackTOHome = () => {
    history.goBack();
  };
  // ---------BackTOHome-------

  // ---------Edit PopOver Open According to Id-------
  const editClassDelete = (value) => {
    // let newArr = ClassList
    // newArr[index].editOpen = !ClassList[index].editOpen
    setClassList((prevState) => {
      return prevState.map((item) => {
        //gets everything that was already in item, and updates "done"
        //else returns unmodified item
        return item.cc_class_id === value
          ? { ...item, editOpen: !item.editOpen }
          : item;
      });
      // let obj = prevState.find(item => ClassList[value]);
      // if(obj){
      // obj.editOpen = !ClassList[index].editOpen
      // }
      // // if(obj !== undefined) {
      // //   obj.name = "Demo"; // <-- state mutation
      // // }
      // return [...prevState];
    });
    // let classlist = ClassList
    // classlist[index].editOpen = !classlist[index]
    // classlist[index].editOpen = !classlist[index].editOpen;
    // this.setState(ClassList)
    // setClassList(ClassList);
    // setClassList((prev) => [...prev, {ClassList[[editOpen] : !editOpen}])
  };
  // ---------Edit PopOver Open According to Id-------

  // ---------getClassList-------
  function getClass() {
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data = "" } = history.location.state;
    setAcademicYear(data.academic_year);
    setAcademicYearId(data.academic_year_id);
    // this.setState({
    //     academicYear: data.academic_year,
    //     academicYearId: data.academic_year_id
    // })
    // const { filter, loadMoreCount } = this.state
    
    const value = {
      filter_by: filter,
      page_no: loadMoreCount,
      class_academic_year: data.year_ids,
      limit : numBoxes
    };
      dispatch(getClassList(value));
    // this.props.getClassList(value)
  }
  // ---------getClassList-------

  // ---------Load More List-------
  const loadMoreClass = () => {
    let value = loadMoreCount;
    value++;
    setLoadMoreCount(value);
    setLoadMoreCountStatus(true);
    const { data = "" } = history.location.state;

        if(searchText && searchText.length > 0) {
            let payload = {
                search_string: searchText,
                academic_year_id: data.academic_year_id,
                page_no: value,
                limit : numBoxes
              };
              dispatch(searchClass(payload));
        } else {
            const payload = {
                filter_by: filter,
                page_no: value,
                class_academic_year: data.academic_year_id,
              };
                dispatch(getClassList(payload));
        }
    // this.setState({
    //     loadMoreCount: value,
    //     loadMoreCountStatus: true,
    // }, () => {
    //     this.getClass()
    // })
  };
  // ---------Load More List-------

//   useEffect(() => {
//     if (loadMoreCount > 1) {
//       getClass();
//     }
//   }, [loadMoreCount]);

  // ---------Add New Class-------
  const addNewClass = () => {
    setIsAddClassSubmit(true);
    setLoadMoreCount(1);
    // this.setState({
    //     isAddClassSubmit: true,
    //     loadMoreCount: 1
    // })
    // const { ClassName,
    //     ClassSection,
    //     ClassGrade,
    //     academicYear,
    //     academicYearId,
    //     filter,
    //     class_id,
    //     AddClassType } = this.state;
    // const { addClass, editClass } = this.props;
    if (!ClassName || (ClassName && ClassName.trim().length <= 0)) {
      return;
    }
    const pattern = /^[a-z0-9 ]+$/i;
    if (!pattern.test(ClassName)) {
      return;
    }
    if (ClassSection && ClassSection.length > 2) {
      // setInvelidMessageEmail('Section has a maximum of 2characters');
      return false;
  } else if (ClassGrade && ClassGrade.length > 2) {
      // setInvelidMessageEmail('Grade name should be of 2 characters');
      return false;
  } else{
    let data = {
      class_section: ClassSection,
      class_name: ClassName,
      class_grade: ClassGrade,
      class_academic_year: academicYear,
      class_academic_year_Id: academicYearId,
      filter_by: filter,
      page_no: 1,
      year_ids: history?.location?.state?.data?.year_ids,
      limit : numBoxes
    };
    if (AddClassType === "add") {
      dispatch(addClass(data));
    } else {
      data.class_id = class_id;
      dispatch(editClass(data));
    }
    window.$("#addClassModal").modal("hide");
  }
  };
  // ---------Add New Class-------

  // ---------New Class Modal-------
  const newClass = () => {
    setClassName("");
    setClassSection("");
    setClassGrade("");
    setAddClassType("add");
    setIsAddClassSubmit(false);
    // this.setState({
    //     ClassName: '',
    //     ClassSection: '',
    //     ClassGrade: '',
    //     AddClassType: 'add',
    //     isAddClassSubmit: false,
    // })
    window.$("#addClassModal").modal("show");
  };
  // ---------New Class Modal-------

  // ---------Edit Class-------
  const editClassOpen = (value) => {
    setClassName(value.cc_class_name);
    setClassSection(value.cc_class_section);
    setClassGrade(value.cc_class_grade);
    setAddClassType("edit");
    setClassId(value.cc_class_id);
    setIsAddClassSubmit(false);
    // this.setState({
    //     ClassName: value.cc_class_name,
    //     ClassSection: value.cc_class_section,
    //     ClassGrade: value.cc_class_grade,
    //     AddClassType: 'edit',
    //     class_id: value.cc_class_id,
    //     isAddClassSubmit: false,
    // })
    window.$("#addClassModal").modal("show");
  };
  // ---------Edit Class-------

  const handleClosePopUp = (e) => {
    setShowPopUp(false);
    // this.setState({
    //     showPopUp: false,
    // })
  };

  // ---------Delete Class-------
  const openNotes = (item) => {
    sessionStorage.setItem("selectedprofile", JSON.stringify(item));
        sessionStorage.setItem("selectedYear", JSON.stringify(academicYear));
        history.push(routes.CLASSNOTE, {  Class_id: class_id,
          academicYearId: academicYearId,
          academicYear: academicYear,
          BackPage: 'Note Select Class',
          ClassData: props.location.state.class_Data,
          classDetail: item,
          // selectedClass: args,
          // classListData: newArr,
         });
  }
  const deleteOldClass = (value) => {
    // setLoadMoreCount(1);
    // this.setState({
    //     loadMoreCount: 1
    // })
    // const { academicYear, academicYearId, filter } = this.state;
    const data = {
      class_id: value.cc_class_id,
      class_academic_year: academicYear,
      class_academic_year_Id: academicYearId,
      filter_by: filter,
      page_no: 1,
      limit: numBoxes
    };
    // this.props.deleteClass(data)
    dispatch(deleteClass(data));
    setIsDeleted(false)
  };
  // ---------Delete Class-------

  // ---------Edit Class Color Wheel-------
  const editColorWheel = (value, index) => {
    setLoadMoreCount(1);
    setIsWheelEdited(true);
    setIsWheelIndex(index);
    // this.setState({
    //     loadMoreCount: 1,
    //     isWheelEdited: true,
    //     isWheelIndex: index
    // })
    // const { academicYear, academicYearId, filter } = this.state;
    const data = {
      class_color_wheel:
        value.cc_class_color_wheel_display === "yes" ? "no" : "yes",
      class_id: value.cc_class_id,
      class_academic_year: academicYear,
      class_academic_year_Id: academicYearId,
      filter_by: filter,
      page_no: 1,
    };
    dispatch(editClassColorWheel(data));
  };
  // ---------Edit Class Color Wheel-------

  // ---------handleChange-------
  const handleChange = (event) => {
  
    // setIsAddClassSubmit(false);
    // [event.target.name] = event.target.value;
    // this.setState(
    //     {
    //         isAddClassSubmit: false,
    //         [event.target.name]: event.target.value,
    //     },
    //     () => { }
    // );
  };
  // ---------handleChange-------

  // ---------handleChange-------
  const handleGradeChange = (event) => {
    setIsAddClassSubmit(false);
    [event.target.name] = event.target.value;
    // this.setState(
    //     {
    //         isAddClassSubmit: false,
    //         [event.target.name]: event.target.value,
    //     },
    //     () => { }
    // );
  };
  // ---------handleChange-------
  useEffect(() => {
    sessionStorage.removeItem("updatedDate")
  }, [])
  const handleSearchText = (e) => {
    if(e.target.value.length == 0) {
      const { data = "" } = history.location.state;
      setClassOverAllCount([])
      // this.setState({
      //     academicYear: data.academic_year,
      //     academicYearId: data.academic_year_id
      // })
      // const { filter, loadMoreCount } = this.state
      const value = {
        filter_by: filter,
        page_no: 1,
        class_academic_year: data.academic_year_id,
        limit : numBoxes
      };
        dispatch(getClassList(value));
    }
    if(ClassList.length == ClassOverAllCount[0].count) {
        let data = ClassList.filter((item) => {
            item.cc_class_name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setClassList(data)
    } else {
    if (e.target.value.length > 2) {
      setLoadMoreCount(1)
      let data = {
        search_string: e.target.value,
        academic_year_id: academicYearId,
        page_no: 1,
        limit : numBoxes
      };
      dispatch(searchClass(data));
    }
  
    
    // if (e.target.value.length == 0 || e.target.value.length < 2) {
    //     setSearchText('')
    //  setLoadMoreCount(1)
    //  const { data = "" } = history.location.state;
    // // this.setState({
    // //     academicYear: data.academic_year,
    // //     academicYearId: data.academic_year_id
    // // })
    // // const { filter, loadMoreCount } = this.state
    // const value = {
    //   filter_by: filter,
    //   page_no: 1,
    //   class_academic_year: data.academic_year_id,
    // };
    //   dispatch(getClassList(value));
    // }
}
    // this.setState({
    //     isAddStudentSubmit: false,
    //     InvelidMessageEmail: '',
    //     StudentsWithoutSearch: this.state.StudentList,
    //     searchText: event.target.value
    // }, () => {

    //     if (this.state.searchText.length > 2 || this.state.searchText.length === 0) {
    //         const { location = {} } = this.props
    //         const { state = {} } = location;
    //         const { searchText } = this.state;

    //         const value = {
    //             class_id: '',
    //             filter_by: this.state.filter,
    //             name_filter_by: this.state.nameFilter,
    //             page_no: this.state.loadMoreCount > 1 ? "full" : this.state.loadMoreCount,
    //             academic_year: this.state.academic_year_id,
    //             search_data: searchText,
    //         }
    //         this.props.getRosterList(value)
    //     } else {
    //         this.setState({
    //             StudentList: this.state.StudentsWithoutSearch
    //         })
    //     }
    // })
  };

  return (
    <div className="classDashBoardContainer">
      {/* ---------Class Add ------ */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#f0f0f0",
          height: 176,
        }}
        className="sticky-header"
      >
      <div className="rosterYearMainDiv-RD">
        <div className="display-inline">
          <div className="rosterYearBackDiv-RD">
            <img
              className="calIcon-RD"
              src={back}
              alt=""
              width="60"
              height="40"
              onClick={() => {
                BackTOHome();
              }}
            />
          </div>
          <div className="totalStudentDiv-RD">
            <div className="attRosterYearDiv-RD">
              <p
                className="attClassGrade-RD"
                onClick={() => {
                  BackTOHome();
                }}
              >
                {academicYear}
              </p>
              <i className="rosterForIcon-RD material-icons">chevron_right</i>
              <p className="attClassGrade2-RD" style={{position : 'relative', top : 1}}> Class</p>
            </div>
          </div>
        </div>
        <div>
          <Button
          
            onClick={() => {
              if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                return;
              } else {
              newClass();
              }
            }}
            // className="addNewRoster-RD w-120"
            className = {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "schltchraddclass addNewRoster-RD w-120" : "addNewRoster-RD w-120"}
            data-toggle="modal"
            // data-target="#addClassModal"
            data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#addClassModal'}
            color="primary"
          >
            <img className="addNewRosterIcon-RD" src={addRosterIcon} alt="" style={{width : '1.10rem'}}/>
            <span className="buttonText-RD" style={{position : 'relative', top : 1}}> Class</span>
          </Button>
          {/* <Button
                        onClick={() => {
                            this.setState({
                                UserProfile: '',
                                UserProfileURL: '',
                                showLoaderUpload: false,
                                FirstName: '',
                                LastName: '',
                                Email: '',
                                isError: '',
                                isAddStudentSubmit: false,
                                tabButton: '',
                                InvelidMessageEmail: '',
                            })
                        }}
                        className='addNewRoster-RD' color="primary" data-toggle="modal" data-target="#myModal2" >
                            <img className="addNewRosterIcon-RD" src={addRosterIcon} alt='' />
                            <span className='buttonText-RD' > Student</span>
                        </Button> */}
          {/* <Button className='importRoster-RD' color="primary" data-toggle="modal" data-target="#myModalupload" >
                            <img className="addNewRosterIcon-RD" src={rosterImport} alt='' />
                            <span className='buttonText-RD' >Import</span>
                        </Button> */}
        </div>
      </div>
      {/* <div className="classYearMainDiv">
                    <div className='display-inline'>
                        <div className="academicBackDiv" onClick={() => { BackTOHome() }}>
                            <i className="academicBackIcon material-icons">chevron_left</i>
                            <p className='classYearText'>{history.location.state.BackPage} </p>
                        </div> */}
      {/* <p className='tapandHoldText'>- Tap and hold to rearrange order</p> */}
      {/* </div>

                    <div>
                        <Button onClick={() => { newClass() }} className='addNewClass' data-toggle="modal" data-target="#addClassModal" color="primary"  >
                            <img className="addNewClassIcon" src={addRosterIcon} alt='' />
                            <span className='buttonClassText' > Add New</span>
                        </Button>
                    </div>
                </div> */}
      {/* ---------Class Add ------ */}

      {/* ---------------Filter and total student------------ */}
      {/* <div className="totalClassMainDiv">
                    <div className='totalClassDiv'>
                        <div className="vertical-center-class">
                            <p className='totalClassText'>Total Classes : {Total_Count}</p>
                        </div>
                    </div> */}

      {/* ---------------Filters------------ */}
      {/* <div className='filterDiv'>

                        <div className='listAllClassFilter'>
                            <i onClick={() => { filterSelect('both') }} className={filterBoth.join(' ')}>
                                {filter === 'both' ? 'check_circle' : 'radio_button_unchecked'}
                            </i>
                            <p className='listAllClassFilterText'>List All</p>
                        </div>
                        <div className='myClassFilterDiv'>
                            <i onClick={() => { filterSelect('created_by_me') }} className={filterCreByMe.join(' ')}>
                                {filter === 'created_by_me' ? 'check_circle' : 'radio_button_unchecked'}
                            </i>
                            <p className='listAllClassFilterText'>My Classes</p>
                        </div>
                        <div className='sharedClassDiv'>
                            <i onClick={() => { filterSelect('shared_to_me'); }} className={filterSharByMe.join(' ')}>
                                {filter === 'shared_to_me' ? 'check_circle' : 'radio_button_unchecked'}
                            </i>
                            <p className='listAllClassFilterText'>Shared Classes</p>
                        </div>

                    </div> */}
      {/* ---------------Filter------------ */}

      {/* </div> */}

      <Card className="rst-refresh-section-RD mt-15 br-8">
        <Card.Body>
          <Row className="rst-filterRow-RD">
            <Col xs="12" md="5" lg="6" className="rst-desc-search-col-RD">
              <span className="rst-total-student-RDD">
                Total Classes : {  ClassOverAllCount?.[0]?.count || 0}
              </span>

              {/* ---------------Filters------------ */}
              <div
                className="searchFilter-RD searchClassFilter-RD"
                onClick={() => {
                  // this.setState({
                  //     // searchBar: true,
                  //     // searchText: ''
                  // })
                }}
              >
                <img className="searchFilterIcon-RD" src={iconSearch} alt="" />
                <CommonTextField
                  margin="normal"
                  // variant="outlined"
                  type="FirstName"
                  value={searchText}
                  onChange={(e) => {
                    handleSearchText(e);
                    setSearchText(e.target.value);
                  }}
                  name="searchText"
                  className="searchFilterInputBox-RD"
                  placeholder="Enter Keywords…"
                />
              </div>
            </Col>
            <Col xs="12" md="7" lg="6" className="rst-sort-by-col-RD">
              <div className="rosterfilterDiv-RD">
                <div className="rosterfilterDiv2-RD classFilterDiv">
                  {/* {this.state.nameFilter === 'firstName' ?
                                            <div className='firstNameBlueActiveBtn-RD' onClick={() => { this.filterNameSelect('firstName') }}>
                                                <p className='listAllActiveText-RD'>First Name</p>
                                            </div> :
                                            <div className='firstName-RD' onClick={() => { this.filterNameSelect('firstName') }}>
                                                <p className='listAllText-RD'>First Name</p>
                                            </div>}
                                        {this.state.nameFilter === 'lastName' ?
                                            <div className='lastNameBlueActiveBtn-RD' onClick={() => { this.filterNameSelect('lastName') }}>
                                                <p className='listAllActiveText-RD'>Last Name</p>
                                            </div> :
                                            <div className='lastName-RD' onClick={() => { this.filterNameSelect('lastName') }}>
                                                <p className='listAllText-RD'>Last Name</p>
                                            </div>} */}
                </div>
                {filter === "both" ? (
                  <div
                    className="listAllActiveBlueBtn-RD"
                    onClick={() => {
                      filterSelect("both");
                    }}
                  >
                    <p className="listAllActiveText-RD">List All</p>
                  </div>
                ) : (
                  <div
                    className="listAll-RD"
                    onClick={() => {
                      filterSelect("both");
                    }}
                  >
                    <p className="listAllText-RD">List All</p>
                  </div>
                )}
                {filter === "created_by_me" ? (
                  <div
                    className="myRosterDivBlueActiveBtn-RD"
                    onClick={() => {
                      filterSelect("created_by_me");
                    }}
                  >
                    <p className="listAllActiveText-RD">My Classes</p>
                  </div>
                ) : (
                  <div
                    className="myRosterDiv-RD"
                    onClick={() => {
                      filterSelect("created_by_me");
                    }}
                  >
                    <p className="listAllText-RD">My Classes</p>
                  </div>
                )}
                {filter === "shared_to_me" ? (
                  <div
                    className="sharedRosterDivBlueActiveBtn-RD"
                    onClick={() => {
                      filterSelect("shared_to_me");
                    }}
                  >
                    <p className="listAllActiveText-RD">Shared Classes</p>
                  </div>
                ) : (
                  <div
                    className="sharedRosterDiv-RD"
                    onClick={() => {
                      filterSelect("shared_to_me");
                    }}
                  >
                    <p className="listAllText-RD">Shared Classes</p>
                  </div>
                )}
                {/* {this.state.filter === 'both' ?
                                        <div className='listAllActiveBlueBtn-RD' onClick={() => { this.filterSelect('both') }}>
                                            <p className='listAllActiveText-RD'>List All</p>
                                        </div> :
                                        <div className='listAll-RD' onClick={() => { this.filterSelect('both') }}>
                                            <p className='listAllText-RD'>List All</p>
                                        </div>}
                                    {this.state.filter === 'created_by_me' ?
                                        <div className='myRosterDivBlueActiveBtn-RD' onClick={() => { this.filterSelect('created_by_me') }}>
                                            <p className='listAllActiveText-RD'>My Roster</p>
                                        </div> :
                                        <div className='myRosterDiv-RD' onClick={() => { this.filterSelect('created_by_me') }}>
                                            <p className='listAllText-RD'>My Roster</p>
                                        </div>}
                                    {this.state.filter === 'shared_to_me' ?
                                        <div className='sharedRosterDivBlueActiveBtn-RD' onClick={() => { this.filterSelect('shared_to_me') }}>
                                            <p className='listAllActiveText-RD'>Shared Roster</p>
                                        </div> :
                                        <div className='sharedRosterDiv-RD' onClick={() => { this.filterSelect('shared_to_me') }}>
                                            <p className='listAllText-RD'>Shared Roster</p>
                                        </div>}
                                    {this.state.filter === 'by_class' ?
                                        <div className='sharedRosterDivBlueActiveBtn-RD' onClick={() => { this.filterSelectByClass('by_class') }}>
                                            <p className='listAllActiveText-RD'>Group By Class</p>
                                        </div> :
                                        <div className='sharedRosterDiv-RD' onClick={() => { this.filterSelectByClass('by_class') }}>
                                            <p className='listAllText-RD'>Group By Class</p>
                                        </div>} */}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
        </div>
      {/* ---------------Filter and total student------------ */}

      {/* --------------Class Card Main Div------------ */}
     
      {ClassList.length > 0 ? (
       <>
       <div
        ref={DataRef} 
        // // style={{height : "calc(100vh - 110px)"}}
        style={{
          // height : ClassList.length > 0 ? `auto` : "82vh", 
          height : ClassList.length > 0 ? "auto" : "82vh",
          }}  
         onScroll={handleScroll}
         data-testid='classListDiv'
         >
        <div className="classCardMainDiv">
          {ClassList &&
            ClassList.length > 0 &&
            ClassList.map((item, index) => {
              const standardOpacity = [
                "classOptionStandard cmncls",
                item.class_core_standard === "yes" ? "opacityProp" : "",
              ];
              return (
                <div key={index}
               
                >
                  {deleteDiv === item.cc_class_id ? (
                    <div></div>
                    // <div className="deleteClassCard">
                    //   <p className="deleteClassText">Delete Class</p>
                    //   <p className="deleteWorningClassText">
                    //     Are you sure want to delete all data associated with the
                    //     Class?
                    //   </p>
                    //   <div className="deleteButtonsClassDiv">
                    //     <div
                    //       className="cancelButtonClassText"
                    //       onClick={() => {
                    //         setDeleteDiv("");
                    //         // this.setState({
                    //         //     deleteDiv: ''
                    //         // })
                    //       }}
                    //     >
                    //       Cancel
                    //     </div>
                    //     <div>
                    //       <Button
                    //         onClick={() => {
                    //           deleteOldClass(item);
                    //           setDeleteDiv("");
                    //           // this.setState({
                    //           //     deleteDiv: ''
                    //           // })
                    //         }}
                    //         className="deleteButtonClass"
                    //         color="primary"
                    //       >
                    //         <span className="deleteButtonClassText">
                    //           {" "}
                    //           Delete
                    //         </span>
                    //       </Button>
                    //     </div>
                    //   </div>
                    // </div>
                  ) : (
                    <div style={{color: "black",width: "319px", height: "100px"}} className="classCardDiv classListingCardDiv">
                      {/* ------------Class Info Div----------  */}
                      <div className="classDetailsCard">
                        <div className="w-100"
                        //  onClick={() => {
                        //   let datahere = {
                        //     data : item,
                        //     AcademicData : props?.history?.location?.state?.data,
                        //     BackPage : 'ClassDashboard',
                        //     academicYear : props?.history?.location?.state?.data?.academic_year,
                        //     isClasses :true,
                        //     isComingFromClassDashboard : true
                        //   }
                        //   history.push(routes.SINGLECLASS, { data: item, AcademicData: props?.history?.location?.state?.data, 
                        //     BackPage: 'ClassDashboard', 
                        //   academicYear:props?.history?.location?.state?.data?.academic_year,
                        //    isClasses: true,
                        //    isComingFromClassDashboard : true });
                        //   sessionStorage.setItem("cLsIdForSingleClass", item.cc_class_id)
                        //   sessionStorage.setItem("cLsIdForGBSC", item.cc_class_id)
                        // }}
                        >
                        <div className="textLeft"
                        
                        >
                          <p style={{paddingTop : "5px", color : "black"}} className="className"
                          // onClick={() => {
                          //   let datahere = {
                          //     data : item,
                          //     AcademicData : props?.history?.location?.state?.data,
                          //     BackPage : 'ClassDashboard',
                          //     academicYear : props?.history?.location?.state?.data?.academic_year,
                          //     isClasses :true,
                          //     isComingFromClassDashboard : true
                          //   }
                          //   history.push(routes.SINGLECLASS, { data: item, AcademicData: props?.history?.location?.state?.data, 
                          //     BackPage: 'ClassDashboard', 
                          //   academicYear:props?.history?.location?.state?.data?.academic_year,
                          //    isClasses: true,
                          //    isComingFromClassDashboard : true });
                          //   sessionStorage.setItem("cLsIdForSingleClass", item.cc_class_id)
                          //   sessionStorage.setItem("cLsIdForGBSC", item.cc_class_id)
                          // }}
                          >{item.cc_class_name?.replace(/(.{13})..+/, "$1…")}</p>
                          <p style={{paddingTop : "3px"}}  className="classGradeAndSectionText">
                            Section : {item.cc_class_section}
                          </p>
                          <p className="classGradeAndSectionText">
                            Grade : {item.cc_class_grade}
                          </p>
                          {JSON.parse(sessionStorage.getItem('UserData')).user_type == "teacher" && item.request_pending === 0 ? (
                            <div className="classSharedViewOnlyDiv">
                              <p className="classViewOnlyText">Share pending</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        </div>
                        <div className="textRight">
                          <div style={{ display: "flex", paddingTop: "5px" }}>
                            {item.cc_class_share_user_id === null ? (
                              userLocalData.cc_user_id ==
                              item.created_by
                              ? (
                                item.is_shared == "yes" ? (
                                  item.recent_shared_permission == "read" ? (
                                    <div className="classSharedViewOnlyDiv">
                                      <p className="classViewOnlyText">
                                        Shared by: View Only
                                      </p>
                                    </div>
                                  ) : item.recent_shared_permission ==
                                    "read_write" ? (
                                    <div className="classSharedViewOnlyDiv">
                                      <p className="classViewOnlyText">
                                        Shared by: Create Copy
                                      </p>
                                    </div>
                                  ) : item.recent_shared_permission ==
                                    "collaborate" ? (
                                    <div className="classSharedViewOnlyDiv">
                                      <p className="classViewOnlyText">
                                        Shared by: Collaborate
                                      </p>
                                    </div>
                                    
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            ) : item.cc_class_share_permission == "read" ? (
                              <div className="classViewOnlyDiv">
                                <p className="classViewOnlyText">View Only</p>
                              </div>
                            ) : item.cc_class_share_permission ==
                              "read_write" ? (
                              <div className="classViewOnlyDiv">
                                <p className="classViewOnlyText">Create Copy</p>
                              </div>
                            ) : item.cc_class_share_permission ==
                              "collaborate" ? (
                              <div className="classViewOnlyDiv">
                                <p className="classViewOnlyText">Collaborate</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              id={`PopoverLegacy${index}`}
                              type="button"
                              className="classMenuIcon"
                              onClick={() => editClassDelete(item.cc_class_id)}
                            >
                              <i className="material-icons iconhoverListing">more_vert</i>
                              {/* <UncontrolledPopover
                                isOpen={item.editOpen}
                                target={`PopoverLegacy${index}`}
                                toggle={() => editClassDelete(item.cc_class_id)}
                                trigger="legacy"
                                placement="left"
                                className="popoverClassDelete"
                                style={{ width: 100 }}
                              >
                                <PopoverBody className="popoverClassDelete">
                                  {item.cc_class_share_permission !== null &&
                                  item.cc_class_share_permission === "read" ? (
                                    <div className="disabledEditClasspopOver">
                                      <i className="editClassIcon material-icons">
                                        edit
                                      </i>
                                      <span className="editTextClassPopOver">
                                        Edit
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      className={`editClasspopOver ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'disabledEditClasspopOver' }`}
                                      onClick={() => {
                                        if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                          return
                                        } else {
                                        editClassOpen(item);
                                        }
                                      }}
                                    >
                                      <i className="editClassIcon material-icons">
                                        edit
                                      </i>
                                      <span className="editTextClassPopOver">
                                        Edit
                                      </span>
                                    </div>
                                  )}
                                  {(item.cc_class_share_permission !== null &&
                                  item.cc_class_share_permission === "read") || item.cc_class_share_permission === "collaborate"  ? (
                                    <div className="disabledDeleteClassPopOver">
                                      <i className="editClassIcon material-icons">
                                        delete
                                      </i>
                                      <span className="deleteTextClassPopOver">
                                        Delete
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        // setDeleteDiv(item.cc_class_id);
                                        if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                          return;
                                        } else {
                                        setIsDeleted(true)
                                        setDeleteItem(item)
                                        }
                                        // this.setState({
                                        //     deleteDiv: item.cc_class_id
                                        // })
                                      }}
                                      // className="deleteClassPopOver"
                                      className={`deleteClassPopOver ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'disabledDeleteClassPopOver' }`}
                                    >
                                      <i className="editClassIcon material-icons">
                                        delete
                                      </i>
                                      <span className="deleteTextClassPopOver">
                                        Delete
                                      </span>
                                    </div>
                                  )}
                                </PopoverBody>
                              </UncontrolledPopover> */}
                                <UncontrolledPopover
                                // trigger="legacy"
                                // placement="right-start"
                                // isOpen={item.editOpen}
                                target={`PopoverLegacy${index}`}
                                // toggle={() => editStudent(item.cc_student_id)}
                                style={{ borderRadius: 20 }}
                                className="popover academicPopOver-RD"
                                isOpen={item.editOpen}
                                // target={`PopoverLegacy${index}`}
                                toggle={() => editClassDelete(item.cc_class_id)}
                                trigger="legacy"
                                placement="right-start"
                                // className="popoverClassDelete"
                                // style={{ width: 100 }}
                              >
                                <PopoverBody>
                                  <div className="editInputDiv-RD">
                                    <div className="inputMaskUpdateParentDiv-RD">
                                      <div className="inputMaskUpdateDiv-RD">
                                        <div className="studentOptionDiv-RD">
                                        <div style={{whiteSpace : "nowrap" , border : "none" }} className='levelEditmainFieldDiv'>
                          
                        </div>
                  
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                           

                                            {/* ------------Attendance Div----------  */}
                                            <div
                                                className="classOptionAtt cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                   history.push(routes.CLASSATTENDANCE
                                                       , {
                                                           classDetail: item,
                                                           classList: ClassList,
                                                           academicYear: academicYear,
                                                           isClasses: true,
                                                       }
                                                   );
                                               }}
                                              >
                                                <img
                                                  src={attendance}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                <p className="classOptionText">
                                                Attendance
                                                </p>
                                              </div>
                                          
                                            {/* ------------Notes Div----------  */}
                                            <div
                                           
                                              className="optionBtnDiv-RD noteOptionBtn-RD cmncls"
                                              onClick={() => {
                                                history.push(
                                                  
                                                  routes.CLASSNOTE, {
                                                  Class_id: item.cc_class_id,
                                                  academicYearId: academicYearId,
                                                  academicYear: academicYear,
                                                  BackPage: 'ClassDB',
                                                  ClassData: item,
                                                  classDetail: item.cc_class_name,
                                                  // selectedClass: item,
                                                  classListData: props?.location?.state?.data?.class_data,

                                                
                                              })
                                         

                                            }}
                                            >
                                              <img
                                                src={iconNotes} 
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Notes
                                              </p>
                                            </div>
                                            <div
                                                className="classOptionRoster cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                  history.push(routes.CLASSROSTER, {
                                                    classDetail: item,
                                                    academicYearId: academicYearId,
                                                    academicYear: academicYear,
                                                    classPermission: item.cc_class_share_permission,
                                                    ClassData: item,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={rosterIcon}
                                                  alt=""
                                                  className="classOptionStandardIcons"
                                                />
                                                <p className="classOptionText">
                                                  Roster
                                                </p>
                                              </div>
                                         
                                           
                                            {/* ------------GradeBook Div----------  */}
                                          </div>
                                        
                                          {/* </div> */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "start",
                                              // paddingLeft : "8px"
                                            }}
                                          >
                                           <div
                                                className="classOptionLesson cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                  history.push(routes.CLASSLESSONPLAN
                                                      , {
                                                          classDetail: item, academicYearId: academicYearId,
                                                          academicYear: academicYear, classId: class_id,
                                                          classPermission: item.cc_class_share_permission
                                                      }
                                                  );
                                                                                    }}
                                              >
                                                <img
                                                  src={cardList}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                <p className="classOptionText">
                                                  Lesson Plan
                                                </p>
                                              </div>

                                              <div
                                                className="classOptionRubrics cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                 if (item.student_count === 0) {
                                                     createNotification('error', "Students not enrolled. Please enrol students to create Rubrics.");
                                                     return;
                                                 }
                                                 sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(item));
                                                 history.push(routes.CLASSRUBRICS
                                                     , {
                                                         AcademicData: data,
                                                         classDetailsdata: item,
                                                         backpage: "classes"
                                                     }
                                                 );
                                                                 }}
                                              >
                                                <img
                                                  src={userRubrics}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                <p className="classOptionText">
                                                Rubrics
                                                </p>
                                              </div>
                                              <div
                                                className="classOptionassesment cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                   history.push(routes.FORMATIVEASSESSMENT, { AcademicData: data, BackPage: 'ClassDB',classDetailsdata: item })
                                                   sessionStorage.setItem("cLsIdForSingleClass" , item.cc_class_id)
                                               }}
                                              >
                                                <img
                                                  src={faicon}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                  
                                                <p style={{marginTop : "0px"}} className="classOptionText">
                                                  Formative Assesment
                                                </p>
                                              </div>
                                        
                                        
                                            {/* ------------Delete Div----------  */}
                                           
                                         
                                            
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "start",
                                              // paddingLeft : "8px"
                                            }}
                                          >
                                         
                                         <div
                                              className="optionBtnDiv-RD gradebookOptionBtn-RD cmncls"
                                              onClick={() => {

                                                if (item.is_associated_with_class === 'no') {
                                                    createNotification('error', 'This student is not associated with any Class.');
                                                    return;
                                                }
                                                // getStudentGradebookNullRequest()
                                                history.push(routes.CLASSGRADEBOOK, { id: item.cc_student_id, academicYear: data.academic_year, classId: data.cc_class_id, studentInfo: item, classPermission: data.cc_class_share_permission , AcademicData: data, BackPage: 'class',classDetailsdata: item})
                                                sessionStorage.setItem("cLsIdForGBSC" , item.cc_class_id)
                                            }}
                                            >
                                              <img
                                                src={iconGradeBook}
                                                alt=""
                                                className="optionGreadeBookIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                GradeBook
                                              </p>
                                            </div>
                                        

                                            {/* ------------Delete Div----------  */}

                                           
                                                 <div
                                                className= {standardOpacity.join(' ')}
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                  history.push(routes.CLASSCORESTANDARDS, {
                                                 classDetail: item, academicYearId: academicYearId,
                                                 academicYear: academicYear, classId: class_id,
                                                 classPermission: item.cc_class_share_permission
                                             })
                                         }}
                                              >
                                                <img
                                                  src={standardIcon}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                <p className="classOptionText">
                                                  Standards
                                                </p>
                                              </div>
                                        
                                        
                                              <div
                                                className="classOptionTag cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick={() => {
                                                   history.push(routes.CLASSTAG, {
                                                       classDetail: item, academicYearId: academicYearId,
                                                       classPermission: item.cc_class_share_permission
                                                   })
                                               }}
                                              >
                                                <img
                                                  src={tagIcon}
                                                  alt=""
                                                  className="classOptionIcons"
                                                />
                                                <p className="classOptionText">
                                                  Tag
                                                </p>
                                              </div>
                                          </div>


                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "start",
                                              // paddingLeft : "8px"
                                            }}
                                          >
                                         
                                          

                                            {/* ------------Delete Div----------  */}

                                            {item.cc_class_share_permission !==
                                              null &&
                                            (item.cc_class_share_permission ===
                                              "read" || item.cc_class_share_permission ===
                                              "collaborate") ? (
                                              <div
                                                className="shareOptionDiv-RD cmncls"
                                                style={{ margin: "5px",
                                                //  display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ||  item.cc_class_share_permission ===
                                                // "collaborate" || item.cc_class_share_permission == 'read') && 'none' 
                                              }}
                                              onClick= {() =>
                                                //  {data.cc_class_share_permission == null && (data.cc_class_share_permission !== "read" || item.cc_class_share_permission !== "collaborate") &&
                                               history.push(routes.CLASSSHARE, {
                                                   classDetail: item
                                               })
                                              // } 
                                              }
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                className="shareOptionDiv-RD cmncls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                  // display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'
                                                }}
                                                onClick= {() => {
                                                  // data.cc_class_share_permission == null && (data.cc_class_share_permission !== "read" || item.cc_class_share_permission !== "collaborate") &&
                                                history.push(routes.CLASSSHARE, {
                                                    classDetail: item
                                                }) } 
                                              }
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            )}
                                        
                                              <div
                                                className="shareOptionDiv-RD cmncls editcls"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                  display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_class_share_permission == 'read' ||  item.cc_class_share_permission === "collaborate") && 'none' 
                                                }}
                                                onClick={() => {
                                                  editClassOpen(item);
                                                
                                                }}
                                                
                                              >
                                                <img
                                                  src={editIcon}
                                                  alt=""
                                                  className="classOptionIcons "
                                                />
                                                <p className="classOptionText editclstext">
                                                  Edit
                                                </p>
                                              </div>

                                              {item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" ||  item.cc_class_share_permission ===
                                              "collaborate" ? (
                                              <div className="optionBtnDiv-RD deleteOptionBtn-RD cmncls"  onClick={() => {
                                                // setDeleteDiv(item.cc_class_id);
                                                setIsDeleted(true)
                                                setDeleteItem(item)
                                                // this.setState({
                                                //     deleteDiv: item.cc_class_id
                                                // })
                                              }}
                                              style={{display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_class_share_permission == 'read' ||  item.cc_class_share_permission === "collaborate") && 'none' }}
                                              >
                                                <img
                                                  src={deleteIcon}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Delete
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                // onClick={() => {
                                                //   deletePopoverHandler(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectStudentId(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectIndex(index);
                                                  // this.setState({selectStudentId: item.cc_student_id});
                                                  // this.setState({selectIndex: index}); }}
                                                // }}
                                                // onClick={() => {
                                                //     setRemoveRoster(item.cc_student_id)
                                                // }} 
                                                onClick={() => {
                                                  // setDeleteDiv(item.cc_class_id);
                                                  setIsDeleted(true)
                                                  setDeleteItem(item)
                                                  // this.setState({
                                                  //     deleteDiv: item.cc_class_id
                                                  // })
                                                }}
                                                className="optionBtnDiv-RD deleteOptionBtn-RD cmncls"
                                                style={{display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_class_share_permission == 'read' ||  item.cc_class_share_permission === "collaborate") && 'none' }}
                                              >
                                                <img
                                                  src={deleteIcon2}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Deletee
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                          </div>
                          <div style={{marginTop : 14}}>
                            {item.cc_class_share_permission !== null &&
                            item.cc_class_share_permission === "read" ? (
                              <img
                                src={
                                  item.cc_class_color_wheel_display === "yes"
                                    ? GroupActiveIcon
                                    : GroupIcon
                                }
                                alt=""
                                className="disabledGroupActiveIcon"
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  editColorWheel(item, index);
                                }}
                                src={
                                  item.cc_class_color_wheel_display === "yes"
                                    ? GroupActiveIcon
                                    : GroupIcon
                                }
                                alt=""
                                className="groupActiveIcon"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* ------------Class Info Div----------  */}

                      {/* ------------options Div----------  */}
                      {/* <div className="classAllOptionDiv">
                        {/* ------------Roster Div----------  */}
                        {/* <div className="optionDivs"> 
                          <div
                            className="classOptionRoster"
                            onClick={() => {
                              history.push(routes.CLASSROSTER, {
                                classDetail: item,
                                academicYearId: academicYearId,
                                academicYear: academicYear,
                                classPermission: item.cc_class_share_permission,
                                ClassData: item,
                              });
                            }}
                          >
                            <img
                              src={rosterIcon}
                              alt=""
                              className="classOptionStandardIcons"
                            />
                            <p className="classOptionText">Roster</p>
                          </div>

                          {/* ------------Roster Div----------  */}
                          {/* ------------Standard Div----------  */}
                          {/* <div 
                            className={standardOpacity.join(" ")}
                            onClick={() => {
                              history.push(routes.CLASSCORESTANDARDS, {
                                classDetail: item,
                                academicYearId: academicYearId,
                                academicYear: academicYear,
                                classId: class_id,
                                classPermission: item.cc_class_share_permission,
                              });
                            }}
                          >
                            <img
                              src={standardIcon}
                              alt=""
                              className="classOptionIcons"
                            />
                            <p className="classOptionText">Standards</p>
                          </div>  */}

                          {/* ------------Standard Div----------  */}
                     {/*     <div
                            className="classOptionLesson"
                            onClick={() => {
                              history.push(routes.CLASSLESSONPLAN, {
                                classDetail: item,
                                academicYearId: academicYearId,
                                academicYear: academicYear,
                                classId: class_id,
                                classPermission: item.cc_class_share_permission,
                              });
                            }}
                          >
                            <img
                              src={cardList}
                              alt=""
                              className="classOptionIcons"
                            />
                            <p className="classOptionText">Lesson Plan</p>
                          </div> */}
                          {/* ------------Tag Div----------  */}
                     {/*   </div>
                        <div className="optionDivs">
                          {/* ------------Share Div----------  */}
                       {/*    <div
                            className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "classOptionAtt schltchrclassOption" : "classOptionAtt"}
                            onClick={() => {
                              history.push(routes.CLASSATTENDANCE, {
                                classDetail: item,
                                classList: ClassList,
                                academicYear: academicYear,
                                isClasses: true,
                              });
                            }}
                          >
                            <img
                              src={attendance}
                              alt=""
                              className="classOptionIcons"
                            />
                            <p className="classOptionText">Attendance</p>
                          </div>
                          <div
                          className = {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "classOptionRubrics schltchrclassOption" : "classOptionRubrics"}

                            onClick={() => {
                              if (item.student_count === 0) {
                                createNotification(
                                  "error",
                                  "No students enrolled. Please enrol students to create Rubrics."
                                );
                                return;
                              }
                              sessionStorage.setItem(
                                "rubricsSelectedClass",
                                JSON.stringify(item)
                              );
                              history.push(routes.CLASSRUBRICS, {
                                AcademicData: data,
                                classDetailsdata: item,
                                backpage: "classes",
                              });
                            }}
                          >
                            <img
                              src={userRubrics}
                              alt=""
                              className="classOptionIcons"
                            />
                            <p className="classOptionText">Rubrics</p>
                          </div>
                          <div
                            className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "classOptionTag schltchrclassOption" : "classOptionTag"}
                            onClick={() => {
                              history.push(routes.CLASSTAG, {
                                classDetail: item,
                                academicYearId: academicYearId,
                                classPermission: item.cc_class_share_permission,
                              });
                            }}
                          >
                            <img
                              src={tagIcon}
                              alt=""
                              className="classOptionIcons"
                            />
                            <p className="classOptionText">Tag</p>
                          </div>

                          {/* ------------Tag Div----------  */}
                          {/* ------------Share Div----------  */}
                        {/*  {item.cc_class_share_permission !== null &&
                          item.cc_class_share_permission === "read" && JSON.parse(sessionStorage.getItem('UserData')).user_type != "school_teacher" ? (
                            <div className="disabledClassOptionShare">
                              <img
                                src={userShareIcon}
                                alt=""
                                className="classOptionIcons"
                              />
                              <p className="classOptionText">Share</p>
                            </div>
                          ) : (
                            <div
                              className={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "classOptionShare d-none" : "classOptionShare"}
                              onClick={() => {
                                if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                  return;
                                } else {
                                history.push(routes.CLASSSHARE, {
                                  classDetail: item,
                                });
                              }
                              }}

                            >
                              <img
                                src={userShareIcon}
                                alt=""
                                className="classOptionIcons"
                              />
                              <p className="classOptionText">Share</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* ------------options Div----------  */}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        </div>
         {loader && (
          <div>
            <DivLoader />
          </div>
        )}
        </>
      ) : (
        <div className="noClass noClass-CD"
        ref={noClassRef}
        >
          {loader ? (
             <div>
             <DivLoader />
           </div>
          ) : (
            // <div data-toggle="modal" data-target="#addClassModal">
            //   <p className="tapToAddText">Tap to add New Class</p>

            <div data-toggle="modal" 
            data-target={`${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#addClassModal'}`}
            >
              <p className={`tapToAddText ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'd-none'}`}>Tap to add New Class</p>

              <img
                src={addAcademicImage}
                alt=""
                // className="addClassDashboardImage"
                className={`addClassDashboardImage ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'd-none'}`}
              />
            </div>
          )}
        </div>
      )}
      {ClassList.length > 0 && ClassList.length > 0 && (
        <div style={{ padding: 5 }}>
          {loadMoreCountStatus ? (
            <>
            {/* <Button className="loadMoreClass" color="primary">
              <img
                src={loaderImag}
                alt=""
                className="loaderIconLoadMoreClass d-none"
              />
            </Button> */}
            </>
          ) : (
            <div>
              {
                // searchText.length > 0 ?
                // <>
                // {
                //   searchedClassCount > 10 ?  <Button
                //   onClick={() => {
                //     loadMoreClass();
                //   }}
                //   className="loadMoreClass"
                //   color="primary"
                // >
                //   <i className="material-icons">autorenew</i>
                //   <span className="LoadClassButtonText"> Load More</span>
                // </Button> : <div></div>
                // }
                // </> :
                // <>
                // {ClassOverAllCount &&
                // ClassOverAllCount?.[0]?.count > ClassList?.length &&
                // // searchedClassDataLoad.length == 0 &&
                //  (
                //   <Button
                //     onClick={() => {
                //       loadMoreClass();
                //     }}
                //     className="loadMoreClass"
                //     color="primary"
                //   >
                //     <i className="material-icons">autorenew</i>
                //     <span className="LoadClassButtonText"> Load More</span>
                //   </Button>
                // )}
                // </>
              }
              {/* {ClassOverAllCount &&
                ClassOverAllCount[0].count > ClassList.length &&
                // searchedClassDataLoad.length == 0 &&
                 (
                  <Button
                    onClick={() => {
                      loadMoreClass();
                    }}
                    className="loadMoreClass"
                    color="primary"
                  >
                    <i className="material-icons">autorenew</i>
                    <span className="LoadClassButtonText"> Load More</span>
                  </Button>
                )} */}
            </div>
          )}
        </div>
      )}
      {/* ---------------Class Card Main Div------------ */}

      {/* ---------------Modal Add Class------------ */}
      <div
        className="modal right fade addOfClass"
        id="addClassModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addClassModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="addClassModalHeaderTitle" id="addClassModal">
                Add Class
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelClassText d-flex flex-wrap justify-content-center align-items-center rounded-circle" aria-hidden="true" style={{width : 30, height : 30}}>
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInClassModal"
                    style={{marginTop : 0}}
                  />
                </span>
              </button>
            </div>

            <div className="modal-body">
              {/* -----------All Input Type----------- */}
              <div className="allInputClassMainDiv">
                <div className="classAddInputDiv">
                  {/* <h5 className="classInputLableInfo">Class name</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="ClassName"
                    value={ClassName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setIsAddClassSubmit(false);
                      setClassName(e.target.value);
                    }}
                    name="ClassName"
                    className="classAddInput"
                    placeholder="Enter Class name"
                  />
                  {(!ClassName || (ClassName && ClassName.trim().length <= 0)) && isAddClassSubmit && (
                    <p className="errormessageRegister">
                      Please enter class name
                    </p>
                  )}
{ClassName && ClassName && ClassName.trim().length != 0 && !pattern.test(ClassName) && isAddClassSubmit && (
                             <p className="errormessageRegister">Please enter only letters and numbers</p>
                                                            )}
                </div>
                <div className="classAddInputDiv">
                  {/* <h5 className="classInputLableInfo">Section</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="ClassSection"
                    value={ClassSection}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setIsAddClassSubmit(false);
                      setClassSection(e.target.value);
                    }}
                    name="ClassSection"
                    className="classAddInput"
                    placeholder="Enter Section"
                    maxLength={2}
                  />
                   {( (ClassSection && ( ClassSection.length > 2 )) )&& <p className="errormessageRegister">Section has a maximum of 2 characters</p>}
                  {/* {(ClassSection.length > 2 ||
                    (ClassSection.length < 2 && isAddClassSubmit)) && (
                    <p className="errormessageRegister">
                      Section has a maximum of 2characters
                    </p>
                  )} */}
                </div>
                <div className="classAddInputDiv">
                  {/* <h5 className="classInputLableInfo">Grade</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="ClassGrade"
                    value={ClassGrade}
                    // onChange={this.handleGradeChange}
                    onChange={(e) => {
                      setIsAddClassSubmit(false);
                      setClassGrade(e.target.value);
                    }}
                    name="ClassGrade"
                    className="classAddInput"
                    placeholder="Enter Grade"
                    maxLength={2}
                  />

                     {( ClassGrade && (ClassGrade.length > 2 ) ) && <p className="errormessageRegister">Grade name should be of 2 characters</p>}
                  {/* {(ClassGrade.length > 2 ||
                    (ClassGrade.length < 2 && isAddClassSubmit)) && (
                    <p className="errormessageRegister">
                      Grade name should be of 2 characters
                    </p>
                  )} */}
                </div>
                {/* -----------All Input Type----------- */}

                {/* -----------All Button----------- */}
                <div className="modalButtonClassPage">
                  <div>
                    <Button
                      onClick={() => {
                        addNewClass();
                      }}
                      className="saveButtonClass"
                      color="primary"
                      disabled={(ClassName.length > 0 || ClassSection.length > 0 || ClassGrade.length > 0) ? false : true}
                    >
                      <span className="saveTextClass"> Save</span>
                    </Button>
                  </div>
                </div>
                {/* -----------All Button----------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Modal Add Class------------ */}
       {/* ---------------model------------ */}
                {/* Delete Modal */}
                { isDeleted === true &&
                    <Modal centered className="delete-modal-warning br-8" show={isDeleted} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure want to delete all Data associated with the class?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => {
                              // editStudent(selectIndex);
                                // this.setState({ isDelete: false })
                                setIsDeleted(false)
                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            onClick={() => {
                              // deleteStudentHandler(selectStudentId, selectIndex)
                              deleteOldClass(deleteItem);
                             }}
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

      {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
    </div>
  );
};

// class ClassDashboard extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             filter: 'both',
//             academicYear: '',
//             academicYearId: '',
//             class_id: '',
//             ClassList: [],
//             ClassOverAllCount: [],
//             deleteDiv: '',
//             ClassName: '',
//             ClassSection: '',
//             ClassGrade: '',
//             AddClassType: 'add',
//             isAddClassSubmit: false,
//             loadMoreCount: 1,
//             loadMoreCountStatus: false,

//             isWheelEdited: false,
//             isWheelIndex: '',
//             showPopUp: false,

//         }
//         this.editClass = this.editClass.bind(this);

//     }

//     // ---------Open Sidebar by Defult-------
//     componentDidMount() {
//         if (this.props && this.props.classData && this.props.classData.sideMenu) {
//             window.$('#addClassModal').modal('show');
//             this.props.setClassSidebarOpen(false)
//         }

//     }
//     // ---------Open Sidebar by Defult-------

//     // ---------UNSAFE_componentWillMount-------
//     UNSAFE_componentWillMount() {
//         this.getClass()
//     }
//     // ---------UNSAFE_componentWillMount-------

//     // ---------componentWillReceiveProps-------
//     async UNSAFE_componentWillReceiveProps(nextProps) {
//         const { isWheelEdited,
//             isWheelIndex,
//             loadMoreCount,
//             ClassList } = this.state
//         // ---------get Class WillReceiveProps-------
//         if (!isWheelEdited) {
//             if (nextProps.classData.action === 'GET_CLASS_LIST_SUCCESS') {
//                 if (nextProps.classData.class_Data && nextProps.classData.class_Data.status === 'Success') {
//                     if (nextProps.classData.class_Data.response_data[0] && nextProps.classData.class_Data.response_data[0].length > 0) {
//                         if (loadMoreCount > 1) {
//                             let getClassData = ClassList;
//                             for (let value of nextProps.classData.class_Data.response_data[0]) {
//                                 getClassData.push(value)
//                             }
//                             this.setState({
//                                 ClassList: getClassData,
//                                 ClassOverAllCount: nextProps.classData.class_Data.response_data[1],
//                                 loadMoreCountStatus: false
//                             })
//                         } else {
//                             this.setState({
//                                 ClassList: nextProps.classData.class_Data.response_data[0],
//                                 ClassOverAllCount: nextProps.classData.class_Data.response_data[1]
//                             })
//                         }

//                     } else {
//                         this.setState({
//                             ClassList: [],
//                             ClassOverAllCount: []
//                         })
//                     }
//                 }

//             }
//         }

//         // ---------get Class WillReceiveProps-------

//         if (nextProps.classData.action === 'ADD_CLASS_FAILURE'
//             || 'EDIT_CLASS_FAILURE') {
//             if (nextProps.classData.error &&
//                 (nextProps.classData.error.message === 'Note limit has been exceeded. Please upgrade the plan'
//                     || nextProps.classData.error.message === "Media limit has been exceeded. Please upgrade the plan"
//                     || nextProps.classData.error.message === "No space available. Please update your subscription plan"
//                 )) {
//                 this.setState({
//                     showPopUp: true
//                 })
//             }
//         }

//         // ---------get Class Color Wheel WillReceiveProps-------
//         if (isWheelEdited) {
//             if (nextProps.classData.action === 'EDIT_CLASS_COLOR_WHEEL_SUCCESS') {
//                 let ClassList = this.state.ClassList;
//                 ClassList[isWheelIndex].cc_class_color_wheel_display = ClassList[isWheelIndex].cc_class_color_wheel_display === 'yes' ? 'no' : 'yes';
//                 this.setState({
//                     ClassList: ClassList,
//                     isWheelEdited: false,
//                     isWheelIndex: ''
//                 })
//             }
//         }
//         // ---------get Class Color Wheel WillReceiveProps--------

//     }
//     // ---------componentWillReceiveProps-------

//     // ---------getRoster by Filter-------
//     filterSelect = (value) => {
//         this.setState({
//             filter: value,
//             loadMoreCount: 1
//         }, () => {
//             this.getClass();
//         })

//     }
//     // ---------getRoster by Filter-------

//     // ---------BackTOHome-------
//     BackTOHome = () => {
//         this.props.history.goBack();
//     }
//     // ---------BackTOHome-------

//     // ---------Edit PopOver Open According to Id-------
//     editClass(index) {
//         let ClassList = this.state.ClassList;
//         ClassList[index].editOpen = !ClassList[index].editOpen;
//         this.setState(ClassList)
//     }
//     // ---------Edit PopOver Open According to Id-------

//     // ---------getClassList-------
//     getClass() {
//         const { location = {} } = this.props
//         const { state = {} } = location;
//         const { data = '' } = state;
//         this.setState({
//             academicYear: data.academic_year,
//             academicYearId: data.academic_year_id
//         })
//         const { filter, loadMoreCount } = this.state
//         const value = {
//             filter_by: filter,
//             page_no: loadMoreCount,
//             class_academic_year: data.year_ids
//         }
//         this.props.getClassList(value)
//     }
//     // ---------getClassList-------

//     // ---------Load More List-------
//     loadMoreClass() {
//         let value = this.state.loadMoreCount
//         value++
//         this.setState({
//             loadMoreCount: value,
//             loadMoreCountStatus: true,
//         }, () => {
//             this.getClass()
//         })
//     }
//     // ---------Load More List-------

//     // ---------Add New Class-------
//     addNewClass() {
//         this.setState({
//             isAddClassSubmit: true,
//             loadMoreCount: 1
//         })
//         const { ClassName,
//             ClassSection,
//             ClassGrade,
//             academicYear,
//             academicYearId,
//             filter,
//             class_id,
//             AddClassType } = this.state;
//         const { addClass, editClass } = this.props;
//         if (!ClassName) {
//             return;
//         }

//         if (this.state.ClassSection && this.state.ClassSection.length > 2) {
//             this.setState({ InvelidMessageEmail: 'Section has a maximum of 2characters' });
//             return false;
//         } else if (this.state.ClassGrade && this.state.ClassGrade.length > 2) {
//             this.setState({ InvelidMessageEmail: 'Grade name should be of 2 characters' });
//             return false;
//         } else {
//             let data = {
//                 "class_section": ClassSection,
//                 "class_name": ClassName,
//                 "class_grade": ClassGrade,
//                 "class_academic_year": academicYear,
//                 "class_academic_year_Id": academicYearId,
//                 "filter_by": filter,
//                 "page_no": 1,
//             }
//             if (AddClassType === 'add') {
//                 addClass(data)

//             } else {
//                 data.class_id = class_id;
//                 editClass(data)

//             }
//             window.$('#addClassModal').modal('hide');
//             return true;
//         }

//     }
//     // ---------Add New Class-------

//     // ---------New Class Modal-------
//     newClass() {
//         this.setState({
//             ClassName: '',
//             ClassSection: '',
//             ClassGrade: '',
//             AddClassType: 'add',
//             isAddClassSubmit: false,
//         })
//         window.$('#addClassModal').modal('show');
//     }
//     // ---------New Class Modal-------

//     // ---------Edit Class-------
//     editClassOpen = (value) => {
//         this.setState({
//             ClassName: value.cc_class_name,
//             ClassSection: value.cc_class_section,
//             ClassGrade: value.cc_class_grade,
//             AddClassType: 'edit',
//             class_id: value.cc_class_id,
//             isAddClassSubmit: false,
//         })
//         window.$('#addClassModal').modal('show');
//     }
//     // ---------Edit Class-------

//     handleClosePopUp = (e) => {
//         this.setState({
//             showPopUp: false,
//         })
//     }

//     // ---------Delete Class-------
//     deleteOldClass = (value) => {
//         this.setState({
//             loadMoreCount: 1
//         })
//         const { academicYear, academicYearId, filter } = this.state;
//         const data = {
//             "class_id": value.cc_class_id,
//             "class_academic_year": academicYear,
//             "class_academic_year_Id": academicYearId,
//             "filter_by": filter,
//             "page_no": 1,
//         }
//         this.props.deleteClass(data)

//     }
//     // ---------Delete Class-------

//     // ---------Edit Class Color Wheel-------
//     editColorWheel = (value, index) => {
//         const { editClassColorWheel } = this.props;
//         this.setState({
//             loadMoreCount: 1,
//             isWheelEdited: true,
//             isWheelIndex: index
//         })
//         const { academicYear, academicYearId, filter } = this.state;
//         const data = {
//             "class_color_wheel": value.cc_class_color_wheel_display === 'yes' ? 'no' : 'yes',
//             "class_id": value.cc_class_id,
//             "class_academic_year": academicYear,
//             "class_academic_year_Id": academicYearId,
//             "filter_by": filter,
//             "page_no": 1,
//         }
//         editClassColorWheel(data)
//     }
//     // ---------Edit Class Color Wheel-------

//     // ---------handleChange-------
//     handleChange = (event) => {
//         this.setState(
//             {
//                 isAddClassSubmit: false,
//                 [event.target.name]: event.target.value,
//             },
//             () => { }
//         );

//     }
//     // ---------handleChange-------

//     // ---------handleChange-------
//     handleGradeChange = (event) => {
//         this.setState(
//             {
//                 isAddClassSubmit: false,
//                 [event.target.name]: event.target.value,
//             },
//             () => { }
//         );

//     }
//     // ---------handleChange-------

//     render() {
//         let { ClassList,
//             ClassOverAllCount,
//             filter,
//             deleteDiv,
//             academicYearId,
//             loadMoreCountStatus,
//             ClassName,
//             isAddClassSubmit,
//             showPopUp,
//             academicYear,
//             class_id,
//             ClassSection,
//             ClassGrade } = this.state;
//         const { loader, history, location } = this.props;
//         const { state = {} } = location;
//         const { data = '' } = state;

//         let Total_Count = 0;
//         if (ClassOverAllCount.length > 0) {
//             Total_Count = ClassOverAllCount[0].count
//         }
//         let userLocalData = JSON.parse(sessionStorage.getItem('UserData'))

//         const filterSharByMe = ["material-icons", filter === 'shared_to_me' ? 'checkBoxClassfilter' : 'unCheckBoxClassfilter']
//         const filterBoth = ["material-icons", filter === 'both' ? 'checkBoxClassfilter' : 'unCheckBoxClassfilter']
//         const filterCreByMe = ["material-icons", filter === 'created_by_me' ? 'checkBoxClassfilter' : 'unCheckBoxClassfilter']
//         return (
//             <div className='classDashBoardContainer'>
//                 {/* ---------Class Add ------ */}
//                 <div className="classYearMainDiv">
//                     <div className='display-inline'>
//                         <div className="academicBackDiv" onClick={() => { this.BackTOHome() }}>
//                             <i className="academicBackIcon material-icons">chevron_left</i>
//                             <p className='classYearText'>{history.location.state.BackPage} </p>
//                         </div>
//                         {/* <p className='tapandHoldText'>- Tap and hold to rearrange order</p> */}
//                     </div>

//                     <div>
//                         <Button onClick={() => { this.newClass() }} className='addNewClass' data-toggle="modal" data-target="#addClassModal" color="primary"  >
//                             <img className="addNewClassIcon" src={addRosterIcon} alt='' />
//                             <span className='buttonClassText' > Add New</span>
//                         </Button>
//                     </div>
//                 </div>
//                 {/* ---------Class Add ------ */}

//                 {/* ---------------Filter and total student------------ */}
//                 <div className="totalClassMainDiv">
//                     <div className='totalClassDiv'>
//                         <div className="vertical-center-class">
//                             <p className='totalClassText'>Total Classes : {Total_Count}</p>
//                         </div>
//                     </div>

//                     {/* ---------------Filters------------ */}
//                     <div className='filterDiv'>

//                         <div className='listAllClassFilter'>
//                             <i onClick={() => { this.filterSelect('both') }} className={filterBoth.join(' ')}>
//                                 {filter === 'both' ? 'check_circle' : 'radio_button_unchecked'}
//                             </i>
//                             <p className='listAllClassFilterText'>List All</p>
//                         </div>
//                         <div className='myClassFilterDiv'>
//                             <i onClick={() => { this.filterSelect('created_by_me') }} className={filterCreByMe.join(' ')}>
//                                 {filter === 'created_by_me' ? 'check_circle' : 'radio_button_unchecked'}
//                             </i>
//                             <p className='listAllClassFilterText'>My Classes</p>
//                         </div>
//                         <div className='sharedClassDiv'>
//                             <i onClick={() => { this.filterSelect('shared_to_me'); }} className={filterSharByMe.join(' ')}>
//                                 {filter === 'shared_to_me' ? 'check_circle' : 'radio_button_unchecked'}
//                             </i>
//                             <p className='listAllClassFilterText'>Shared Classes</p>
//                         </div>

//                     </div>
//                     {/* ---------------Filter------------ */}

//                 </div>
//                 {/* ---------------Filter and total student------------ */}

//                 {/* --------------Class Card Main Div------------ */}
//                 {loader && <div >
//                     <DivLoader />
//                 </div>}
//                 {ClassList.length > 0 ? <div className='classCardMainDiv'>
//                     {ClassList && ClassList.length > 0 && ClassList.map((item, index) => {
//                         const standardOpacity = ['classOptionStandard', item.class_core_standard === 'yes' ? 'opacityProp' : ''];
//                         return (<div key={index}>
//                             {deleteDiv === item.cc_class_id ? <div className='deleteClassCard'>
//                                 <p className='deleteClassText'>Delete Class</p>
//                                 <p className='deleteWorningClassText'>Are you sure want to delete all data associated with the Class?</p>
//                                 <div className='deleteButtonsClassDiv'>
//                                     <div className='cancelButtonClassText' onClick={() => {
//                                         this.setState({
//                                             deleteDiv: ''
//                                         })
//                                     }}>
//                                         Cancel
//                                     </div>
//                                     <div>
//                                         <Button onClick={() => {
//                                             this.deleteOldClass(item)
//                                             this.setState({
//                                                 deleteDiv: ''
//                                             })
//                                         }} className='deleteButtonClass' color="primary" >
//                                             <span className='deleteButtonClassText' > Delete</span>
//                                         </Button>
//                                     </div>
//                                 </div>
//                             </div> :
//                                 <div className='classCardDiv'>

//                                     {/* ------------Class Info Div----------  */}
//                                     <div className='classDetailsCard'>
//                                         <div className='textLeft'>
//                                             <p className='className'>{item.cc_class_name}</p>
//                                             <p className='classGradeAndSectionText'>Section : {item.cc_class_section}</p>
//                                             <p className='classGradeAndSectionText'>Grade : {item.cc_class_grade}</p>
//                                             {item.request_pending === 0 ? <div className='classSharedViewOnlyDiv'><p className='classViewOnlyText'>Share pending</p></div> : ""}
//                                         </div>
//                                         <div className='textRight'>
//                                             <div style={{ display: 'flex' }}>
//                                                 {item.cc_class_share_user_id === null ?
//                                                     userLocalData.cc_user_id == item.cc_class_user_id ?
//                                                         item.is_shared == "yes" ?
//                                                             item.recent_shared_permission == "read" ?
//                                                                 <div className='classSharedViewOnlyDiv'><p className='classViewOnlyText'>Shared by: View Only</p></div>
//                                                                 : item.recent_shared_permission == "read_write" ?
//                                                                     <div className='classSharedViewOnlyDiv'><p className='classViewOnlyText'>Shared by: Create Copy</p></div>
//                                                                     : item.recent_shared_permission == "collaborate" ?
//                                                                         <div className='classSharedViewOnlyDiv'><p className='classViewOnlyText'>Shared by: Collaborate</p></div>
//                                                                         : "" : "" : ""
//                                                     : item.cc_class_share_permission == "read" ? <div className='classViewOnlyDiv'><p className='classViewOnlyText'>View Only</p></div>
//                                                         : item.cc_class_share_permission == "read_write" ? <div className='classViewOnlyDiv'><p className='classViewOnlyText'>Create Copy</p></div>
//                                                             : item.cc_class_share_permission == "collaborate" ? <div className='classViewOnlyDiv'><p className='classViewOnlyText'>Collaborate</p></div> : ""
//                                                 }
//                                                 <div id={`PopoverLegacy${index}`} type="button" className='classMenuIcon' onClick={() => this.editClass(index)}>
//                                                     <i className="material-icons">more_vert</i>
//                                                     <UncontrolledPopover
//                                                         isOpen={item.editOpen}
//                                                         target={`PopoverLegacy${index}`}
//                                                         toggle={() => this.editClass(index)}
//                                                         trigger="legacy"
//                                                         placement="left"
//                                                         className='popoverClassDelete'
//                                                         style={{ width: 100 }}>
//                                                         <PopoverBody className='popoverClassDelete'>
//                                                             {item.cc_class_share_permission !== null && item.cc_class_share_permission === "read" ?
//                                                                 <div className='disabledEditClasspopOver'>
//                                                                     <i className="editClassIcon material-icons">edit</i>
//                                                                     <span className='editTextClassPopOver'>Edit</span>
//                                                                 </div> :
//                                                                 <div className='editClasspopOver' onClick={() => { this.editClassOpen(item) }}>
//                                                                     <i className="editClassIcon material-icons">edit</i>
//                                                                     <span className='editTextClassPopOver'>Edit</span>
//                                                                 </div>}
//                                                             {item.cc_class_share_permission !== null && item.cc_class_share_permission === "read" ?
//                                                                 <div className='disabledDeleteClassPopOver'>
//                                                                     <i className="editClassIcon material-icons">delete</i>
//                                                                     <span className='deleteTextClassPopOver'>Delete</span>
//                                                                 </div> :
//                                                                 <div onClick={() => {
//                                                                     this.setState({
//                                                                         deleteDiv: item.cc_class_id
//                                                                     })
//                                                                 }} className='deleteClassPopOver'>
//                                                                     <i className="editClassIcon material-icons">delete</i>
//                                                                     <span className='deleteTextClassPopOver'>Delete</span>
//                                                                 </div>}

//                                                         </PopoverBody>
//                                                     </UncontrolledPopover>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 {item.cc_class_share_permission !== null && item.cc_class_share_permission === "read" ? <img src={item.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon} alt='' className='disabledGroupActiveIcon' />
//                                                     : <img onClick={() => { this.editColorWheel(item, index) }} src={item.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon} alt='' className='groupActiveIcon' />}

//                                             </div>
//                                         </div>

//                                     </div>
//                                     {/* ------------Class Info Div----------  */}

//                                     {/* ------------options Div----------  */}
//                                     <div className='classAllOptionDiv'>
//                                         {/* ------------Roster Div----------  */}
//                                         <div className="optionDivs">
//                                             <div className='classOptionRoster' onClick={() => {
//                                                 history.push(routes.CLASSROSTER, {
//                                                     classDetail: item, academicYearId: academicYearId, academicYear: academicYear,
//                                                     classPermission: item.cc_class_share_permission, ClassData: item

//                                                 })
//                                             }}>
//                                                 <img src={rosterIcon} alt='' className='classOptionStandardIcons' />
//                                                 <p className='classOptionText'>Roster</p>

//                                             </div>

//                                             {/* ------------Roster Div----------  */}
//                                             {/* ------------Standard Div----------  */}
//                                             <div className={standardOpacity.join(' ')} onClick={() => {
//                                                 history.push(routes.CLASSCORESTANDARDS, {
//                                                     classDetail: item, academicYearId: academicYearId,
//                                                     academicYear: academicYear, classId: class_id,
//                                                     classPermission: item.cc_class_share_permission
//                                                 })
//                                             }}>
//                                                 <img src={standardIcon} alt='' className='classOptionIcons' />
//                                                 <p className='classOptionText'>Standards</p>
//                                             </div>

//                                             {/* ------------Standard Div----------  */}
//                                             <div className='classOptionLesson' onClick={() => {
//                                                 history.push(routes.CLASSLESSONPLAN
//                                                     , {
//                                                         classDetail: item, academicYearId: academicYearId,
//                                                         academicYear: academicYear, classId: class_id,
//                                                         classPermission: item.cc_class_share_permission
//                                                     }
//                                                 );
//                                             }}>
//                                                 <img src={cardList} alt='' className='classOptionIcons' />
//                                                 <p className='classOptionText'>Lesson Plan</p>
//                                             </div>
//                                             {/* ------------Tag Div----------  */}

//                                         </div>
//                                         <div className="optionDivs">
//                                             {/* ------------Share Div----------  */}
//                                             <div className='classOptionAtt' onClick={() => {
//                                                 history.push(routes.CLASSATTENDANCE
//                                                     , {
//                                                         classDetail: item,
//                                                         classList: this.state.ClassList,
//                                                         academicYear: academicYear,
//                                                         isClasses: true,
//                                                     }
//                                                 );
//                                             }}>
//                                                 <img src={attendance} alt='' className='classOptionIcons' />
//                                                 <p className='classOptionText'>Attendance</p>
//                                             </div>
//                                             <div className='classOptionRubrics' onClick={() => {
//                                                 if (item.student_count === 0) {
//                                                     createNotification('error', "No students enrolled. Please enrol students to create Rubrics.");
//                                                     return;
//                                                 }
//                                                 sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(item));
//                                                 history.push(routes.CLASSRUBRICS
//                                                     , {
//                                                         AcademicData: data,
//                                                         classDetailsdata: item,
//                                                         backpage: "classes"
//                                                     }
//                                                 );
//                                             }}>
//                                                 <img src={userRubrics} alt='' className='classOptionIcons' />
//                                                 <p className='classOptionText'>Rubrics</p>
//                                             </div>
//                                             <div className='classOptionTag' onClick={() => {
//                                                 history.push(routes.CLASSTAG, {
//                                                     classDetail: item, academicYearId: academicYearId,
//                                                     classPermission: item.cc_class_share_permission
//                                                 })
//                                             }}>
//                                                 <img src={tagIcon} alt='' className='classOptionIcons' />
//                                                 <p className='classOptionText'>Tag</p>
//                                             </div>

//                                             {/* ------------Tag Div----------  */}
//                                             {/* ------------Share Div----------  */}
//                                             {item.cc_class_share_permission !== null && item.cc_class_share_permission === "read" ?
//                                                 <div className='disabledClassOptionShare'>
//                                                     <img src={userShareIcon} alt='' className='classOptionIcons' />
//                                                     <p className='classOptionText'>Share</p>
//                                                 </div>
//                                                 : <div className='classOptionShare' onClick={() => {
//                                                     history.push(routes.CLASSSHARE, {
//                                                         classDetail: item
//                                                     });
//                                                 }}>
//                                                     <img src={userShareIcon} alt='' className='classOptionIcons' />
//                                                     <p className='classOptionText'>Share</p>
//                                                 </div>}
//                                         </div>
//                                     </div>
//                                     {/* ------------options Div----------  */}
//                                 </div>}
//                         </div>)
//                     })}

//                 </div> :
//                     <div className='noClass'>
//                         {loader ? "" : <div data-toggle="modal" data-target="#addClassModal">
//                             <p className='tapToAddText'>Tap to add New Class</p>

//                             <img src={addAcademicImage} alt='' className='addClassDashboardImage' />
//                         </div>}

//                     </div>
//                 }
//                 {(ClassList.length > 0 && ClassList.length > 0) &&
//                     <div style={{ padding: 5 }}>
//                         {loadMoreCountStatus ? <Button className='loadMoreClass' color="primary" >

//                             <img src={loaderImag} alt='' className='loaderIconLoadMoreClass' />

//                         </Button>
//                             :
//                             <div>
//                                 {ClassOverAllCount && ClassOverAllCount[0].count > ClassList.length &&
//                                     <Button onClick={() => { this.loadMoreClass() }} className='loadMoreClass' color="primary" >
//                                         <i className="material-icons">autorenew</i>
//                                         <span className='LoadClassButtonText' > Load More</span>

//                                     </Button>}
//                             </div>}
//                     </div>}
//                 {/* ---------------Class Card Main Div------------ */}

//                 {/* ---------------Modal Add Class------------ */}
//                 <div className="modal right fade addOfClass" id="addClassModal" tabIndex="-1" role="dialog" aria-labelledby="addClassModal" >
//                     <div className="modal-dialog" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h4 className="modal-title" id="addClassModal">Add Class</h4>
//                                 <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelClassText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInClassModal' /> Cancel</span></button>
//                             </div>

//                             <div className="modal-body">

//                                 {/* -----------All Input Type----------- */}
//                                 <div className='allInputClassMainDiv'>
//                                     <div className='classAddInputDiv'>
//                                         <h5 className='classInputLableInfo'>Class name</h5>
//                                         <CommonTextField
//                                             margin="normal"
//                                             variant="outlined"
//                                             type='ClassName'
//                                             value={ClassName}
//                                             onChange={this.handleChange}
//                                             name="ClassName"
//                                             className="classAddInput"
//                                             placeholder="Enter class name" />
//                                         {(!ClassName && isAddClassSubmit) && <p className="errormessageRegister">Please enter class name</p>}

//                                     </div>
//                                     <div className='classAddInputDiv'>
//                                         <h5 className='classInputLableInfo'>Section</h5>
//                                         <CommonTextField
//                                             margin="normal"
//                                             variant="outlined"
//                                             type='ClassSection'
//                                             value={ClassSection}
//                                             onChange={this.handleChange}
//                                             name="ClassSection"
//                                             className="classAddInput"
//                                             placeholder="Enter section"
//                                             maxLength={2} />
//                                         {(ClassSection.length > 2 || ClassSection.length < 2 && isAddClassSubmit) && <p className="errormessageRegister">Section has a maximum of 2characters</p>}

//                                     </div>
//                                     <div className='classAddInputDiv'>
//                                         <h5 className='classInputLableInfo'>Grade</h5>
//                                         <CommonTextField
//                                             margin="normal"
//                                             variant="outlined"
//                                             type='ClassGrade'
//                                             value={ClassGrade}
//                                             onChange={this.handleGradeChange}
//                                             name="ClassGrade"
//                                             className="classAddInput"
//                                             placeholder="Enter grade"
//                                             maxLength={2}
//                                         />
//                                         {(ClassGrade.length > 2 || ClassGrade.length < 2 && isAddClassSubmit) && <p className="errormessageRegister">Grade name should be of 2 characters</p>}

//                                     </div>
//                                     {/* -----------All Input Type----------- */}

//                                     {/* -----------All Button----------- */}
//                                     <div className="modalButtonClassPage">
//                                         <div>
//                                             <Button onClick={() => { this.addNewClass() }} className='saveButtonClass' color="primary" >
//                                                 <span className='saveTextClass' > Save</span>
//                                             </Button>
//                                         </div>

//                                     </div>
//                                     {/* -----------All Button----------- */}

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {/* ---------------Modal Add Class------------ */}
//                 {showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
//             </div>
//         )
//     }
// }
// const mapStateToProps = state => ({
//     state: state,
//     classData: state.class,
//     loader: state.class.loader
// });
// const mapDispatchToProps = dispatch => {
//     return {
//         getClassList: (data) => dispatch(getClassList(data)),
//         addClass: (data) => dispatch(addClass(data)),
//         editClass: (data) => dispatch(editClass(data)),
//         deleteClass: (data) => dispatch(deleteClass(data)),
//         editClassColorWheel: (data) => dispatch(editClassColorWheel(data)),
//         setClassSidebarOpen: (data) => dispatch(setClassSidebarOpen(data)),

//     };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(
//     ClassDashboard
// );

export default ClassDashboard;