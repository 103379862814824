import React, { useEffect, useState } from 'react';
import { csv } from 'csvtojson';
import axios from 'axios';
import './CsvView.scss';

const CsvView = (props) => {
    const { showCsvUrl } = props;
    const [csvData, setCsvData] = useState(null);

    // const getFile = async () => {
    //     console.log('showCsvUrl', showCsvUrl)
    //     try {
    //         let res = await axios.get(showCsvUrl);
    //         console.log('res', res)
    //         csv({
    //             noheader: true,
    //             output: "csv"
    //         })
    //             .fromString(res.data)
    //             .then((csvRow) => {
    //                 console.log('res.data', res.data)
    //                 setCsvData(csvRow);
    //             });
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    const getFile = async () => {
        try {
            let res = await axios.get(showCsvUrl);
    
            // Check if the response data is a JSON object indicating no records
            if (typeof res.data === 'object' && res.data.message === "No record found.") {
                setCsvData([]); // Set to an empty state for no data
            } else if (typeof res.data === 'string') {
                // Parse CSV data if it's a string
                csv({
                    noheader: true,
                    output: "csv",
                })
                    .fromString(res.data)
                    .then((csvRow) => {
                        console.log('Parsed CSV data:', csvRow);
                        setCsvData(csvRow); // Set the parsed CSV data
                    })
                    .catch((parseError) => {
                        console.error('Error parsing CSV data:', parseError);
                        setCsvData([]); // Handle parsing error gracefully
                    });
            } else {
                console.error('Unexpected response format:', res.data);
                setCsvData([]); // Handle unexpected formats
            }
        } catch (err) {
            console.error('Error fetching CSV:', err);
            setCsvData([]); // Handle errors gracefully
        }
    };
    
    useEffect(() => {
        getFile();
    }, [])

    useEffect(() => {
        
        if(!csvData) {
            getFile()
        }
    },[csvData])
    return (
        <div className="csvContainer">
            <p className="reportTitle">Csv report</p>
            <table style={{ width: '100%', minWidth: '400px' }}>
                <tbody>
                    {csvData?.length != 0 ?
                        csvData?.map((row, i) => {
                            return (
                                <tr key={i}>
                                    {
                                        row.map((col, x) => {
                                            return (
                                                <td key={x}>
                                                    <p>{col}</p>
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                        : <p>NO DATA FOUND</p>}
                </tbody>
            </table>
        </div>
    );
}
export default CsvView;