import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios';
import DatePicker from 'react-datepicker'
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from "../../../Config/Constant";
import PreviewAttReport from './PreviewAttReport';
import eye from 'Assets/AttendanceModule/eye.svg'
import blueComment from 'Assets/AttendanceModule/cmmnt.svg'
import disBest from 'Assets/AttendanceModule/disBest.svg'
import getReport from 'Assets/AttendanceModule/getReport.svg'
import csvView from 'Assets/AttendanceModule/csvView.svg'
import pdfView from 'Assets/AttendanceModule/pdfView.svg'
import rangeHappy from 'Assets/AttendanceModule/rangeHappy.svg'
import rangeSad from 'Assets/AttendanceModule/rangeSad.svg'
import rangeFair from 'Assets/AttendanceModule/rangeFair.svg'
import rangeAbsent from 'Assets/AttendanceModule/rangeAbsent.svg'
import disGood from 'Assets/AttendanceModule/disGood.svg'
import disFair from 'Assets/AttendanceModule/disFair.svg'
import disNoneAtt from 'Assets/AttendanceModule/disNoneAtt.svg'
import bestAtt from 'Assets/AttendanceModule/bestAtt2.png'
import goodAtt from 'Assets/AttendanceModule/goodAtt2.png'
import fairAtt from 'Assets/AttendanceModule/fairAtt2.png'
import crossMark from 'Assets/AttendanceModule/crossMark.svg'
import noneAtt from 'Assets/AttendanceModule/noneAtt2.png'
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import calendarIcon from 'Assets/NoteModule/calendar.svg'
import { createNotification } from 'Config/notificationtoast'
import { getFormattedDate, addDaysGetSpan, getRelativeDayInWeek } from 'Utils/Helper'
import './ClassAttendanceDashboard.scss'
import warningIcon from 'Assets/CommonComponent/warning.png'
import back from 'Assets/CommonComponent/back.png'
import SelectSearch from 'react-select-search';
import { base_Microservice_url } from 'Config/Api';
import { addYears } from 'date-fns';
import Report from './Report.jsx';

const AddEditFirstClassAttendance = props => {
    const {
        getClassAttList,
        getClassAttByDateRange,
        classAttByDateRangeList,
        sendEmailReport,
        addClassAtt,
        addClassAttList,
        getClassAtt,
        setEarlierDate,
        earlierDate,
        history,
        state,
        setIsFirstOpen,
        isSelected,
        changeSelected,
        classNameChangeHandler,
        changeClassName,
        setopenReportComponent,
        openReportComponent
    } = props;
    const { loader } = state?.classAttendance;
    const { classDetail, academicYear, selectedClass, classListData, classList, byAttendanceBtn } = history?.location?.state;
    const isClasses = history?.location?.state?.isClasses;
    const [selectedClassName, setSelectedClassName] = useState(selectedClass ? selectedClass : classDetail.cc_class_name)
    const selectedClassName2 = selectedClass ? selectedClass[0].name : classDetail.cc_class_name
    const [classId, setClassId] = useState();
    const [addDate, setAddDate] = useState(earlierDate ? earlierDate : new Date());
    const [updatedDate, setUpdatedDate] = useState('');
    const [updatedDateTwo, setUpdatedDateTwo] = useState('');
    const [getClassAttStudentList, setGetClassAttStudentList] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [showViewAtt, setShowViewAtt] = useState(false);
    const [showPdfReport, setShowPdfReport] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [byBack, setByBack] = useState('');
    const [defaultList, setDefaultList] = useState();
    const [isEdited, setIsEdited] = useState(false);
    const [openUserId, setOpenUserId] = useState();
    const [commentData, setCommentData] = useState('');
    const [showParticularAtt, setShowParticularAtt] = useState(false);
    const [showRangeAtt, setShowRangeAtt] = useState(false);
    // const [isSelected, setIsSelected] = useState(true);
    const [showDateCal, setShowDateCal] = useState("Single Day");
    const [firstDate, setFirstDate] = useState("");
    const [rangeSecondDate, setRangeSecondDate] = useState("");
    const [monthFirstDate, setMonthFirstDate] = useState("");
    const [yearFirstDate, setYearFirstDate] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewType, setShowPreviewType] = useState("");
    const [showCsvUrl, setShowCsvUrl] = useState("");
    const [upoadFile, setUpoadFile] = useState("");
    const [monthSecondDate, setMonthSecondDate] = useState("");
    const [yearSecondDate, setYearSecondDate] = useState("");
    const [weekFirstDate, setWeekFirstDate] = useState("");
    const [weekSecondDate, setWeekSecondDate] = useState("");
    const [weekFirstDatePre, setWeekFirstDatePre] = useState("");
    const [weekSecondDatePre, setWeekSecondDatePre] = useState("");
    const [firstDatePre, setFirstDatePre] = useState("");
    const [rangeSecondDatePre, setRangeSecondDatePre] = useState("");
    const [monthFirstDatePre, setMonthFirstDatePre] = useState("");
    const [yearFirstDatePre, setYearFirstDatePre] = useState("");
    const [monthSecondDatePre, setMonthSecondDatePre] = useState("");
    const [yearSecondDatePre, setYearSecondDatePre] = useState("");
    const [recordFound, setRecordFound] = useState("");
    const [newArr, setNewArr] = useState([]);
    const classAtteContainer = ['classAttendanceContainer', openUserId ? 'eventNone' : ''];
    const userData = JSON.parse(sessionStorage.getItem('UserData'));
    const userFirstName = userData.cc_user_first_name;
    const userLastName = userData.cc_user_last_name;
    const userSchoolName = userData.cc_user_school_name;
    const fullTeacherName = userData?.cc_user_first_name + ' ' + userData?.cc_user_last_name
    const listAllClassAttSingle = ['myClassFilterAttDiv', showDateCal === "Single Day" ? "selectedDivClr" : ""];
    const myClassFilterAttToday = ['myClassFilterAttTodayDiv', showDateCal === "Today" ? "selectedDivClr" : ""];
    const myClassFilterAttRange = ['myClassFilterAttDiv', showDateCal === "Date Range" ? "selectedDivClr" : ""];
    const myClassFilterAttWeek = ['myClassFilterAttDiv', showDateCal === "This Week" ? "selectedDivClr" : ""];
    const myClassFilterAttMonth = ['myClassFilterAttDiv', showDateCal === "This Month" ? "selectedDivClr" : ""];
    const myClassFilterAttYear = ['myClassFilterAttDiv', showDateCal === "This Year" ? "selectedDivClr" : ""];
    const dateFilterTextSingle = ['listAllClassFilterAttText', showDateCal === "Single Day" ? "selectedTextClr" : ""];
    const dateFilterTextToday = ['listAllClassFilterAttText', showDateCal === "Today" ? "selectedTextClr" : ""];
    const dateFilterTextRange = ['listAllClassFilterAttText', showDateCal === "Date Range" ? "selectedTextClr" : ""];
    const dateFilterTextWeek = ['listAllClassFilterAttText', showDateCal === "This Week" ? "selectedTextClr" : ""];
    const dateFilterTextMonth = ['listAllClassFilterAttText', showDateCal === "This Month" ? "selectedTextClr" : ""];
    const dateFilterTextYear = ['listAllClassFilterAttText', showDateCal === "This Year" ? "selectedTextClr" : ""];
    const displayFlexAttClassRoster = ['displayFlexAttClassRoster', showDateCal === "Today" ? "calMargin" : ""];
    const emojiDivClickNone = ['emojiIcons', 'showViewAttEmoji'];
    const emojiDivFairClickNone = ['emojiIconsFair', 'showViewAttEmoji'];
    const signDate = sessionStorage.getItem('separatorFormat');
    const formatDate = sessionStorage.getItem('dateFormat');
    const device_udid = sessionStorage.getItem('UDID');
    const userLocalData = JSON.parse(sessionStorage.getItem('UserData'))
    const _ = require('lodash');
    const base_url_API = BASE_URL + "v3/";
    const [reportType, setReportType] = useState("PDF");
    useEffect(() => {
        const formattedDate = getFormattedDate(addDate, formatDate, signDate)
   
        setUpdatedDate(formattedDate)
        setUpdatedDateTwo(formattedDate)
    }, [addDate])
   
    useEffect(() => {

        if (showDateCal === "This Week") {
            const firstday = getRelativeDayInWeek(new Date(), 1);
            const lastDate = getRelativeDayInWeek(new Date(), 7);
            setWeekFirstDatePre(firstday);
            const formattedDate = getFormattedDate(firstday, formatDate, signDate);
            setWeekFirstDate(formattedDate);
            const formattedDateSecond = getFormattedDate(lastDate, formatDate, signDate);
            setWeekSecondDatePre(lastDate);
            setWeekSecondDate(formattedDateSecond);
        }

        if (showDateCal === "Date Range") {

            const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
            setFirstDate(formattedDate);
            const spanDate = addDaysGetSpan(new Date(), 1);
            const secondFormattedDate = getFormattedDate(spanDate, formatDate, signDate)
            setRangeSecondDate(secondFormattedDate);
        }

        if (showDateCal === "This Month") {
            const date = new Date();
            const firstday = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const formattedDate = getFormattedDate(firstday, formatDate, signDate);
            setMonthFirstDate(formattedDate);
            const secondMonthDate = getFormattedDate(lastDate, formatDate, signDate)
            setMonthSecondDate(secondMonthDate);
        }

        if (showDateCal === "This Year") {
            const currentDate = new Date();
            const theFirst = new Date(currentDate.getFullYear(), 0, 1);
            const theLast = new Date(currentDate.getFullYear(), 11, 31);
            const formattedDate = getFormattedDate(theFirst, formatDate, signDate);
            setYearFirstDate(formattedDate);
            const secondYearDate = getFormattedDate(theLast, formatDate, signDate)
            setYearSecondDate(secondYearDate);
        }

    }, [showDateCal])


    useEffect(() => {
        if (
            addClassAttList &&
            addClassAttList.message === 'Attendance added successfully'
        ) {
            if (classDetail.cc_class_id !== undefined) {
                setClassId(classDetail.cc_class_id)
                const data = {
                    date: getDateFormatAsApi(addDate),
                    class_id: classDetail.cc_class_id
                }
                getClassAttList(data)
            } else if (classDetail === '') {
                setClassId(classDetail)
                const data = {
                    date: getDateFormatAsApi(addDate),
                    class_id: classDetail
                }
                getClassAttList(data)
            }
            // else if(typeof classDetail !== 'object' && classDetail > 0) {
            //     const data = {
            //       date: getDateFormatAsApi(addDate),
            //       class_id: classDetail
            //     }
            //     getClassAttList(data)
            //   }

        }
    }, [addClassAttList])

    useEffect(() => {
        if (!_.isEqual(getClassAttStudentList, defaultList)) {
            setIsEdited(true)
        }
    }, [getClassAttStudentList])

    useEffect(() => {
        if (showDateCal === "Today") {
            setAddDate(new Date());
        }

        if (showDateCal === "This Week") {
            const firstday = getRelativeDayInWeek(new Date(), 1);
            const lastDate = getRelativeDayInWeek(new Date(), 7);
            setWeekFirstDatePre(firstday);
            const formattedDate = getFormattedDate(firstday, formatDate, signDate);
            setWeekFirstDate(formattedDate);
            const formattedDateSecond = getFormattedDate(lastDate, formatDate, signDate);
            setWeekSecondDatePre(lastDate);
            setWeekSecondDate(formattedDateSecond);
        }

        if (showDateCal === "This Month") {
            const date = new Date();
            const firstday = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            setMonthFirstDatePre(firstday);
            const formattedDate = getFormattedDate(firstday, formatDate, signDate);
            setMonthFirstDate(formattedDate);
            setMonthSecondDatePre(lastDate);
        }
        if (showDateCal === "This Year") {
            const currentDate = new Date();
            const theFirst = new Date(currentDate.getFullYear(), 0, 1);
            const theLast = new Date(currentDate.getFullYear(), 11, 31);
            setYearFirstDatePre(theFirst);
            const formattedDate = getFormattedDate(theFirst, formatDate, signDate);
            setYearFirstDate(formattedDate);
            setYearSecondDatePre(theLast);
            const secondYearDate = getFormattedDate(theLast, formatDate, signDate)
            setYearSecondDate(secondYearDate);
        }
        if (showDateCal === "Date Range") {
            setFirstDatePre(new Date());
            const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
            setFirstDate(formattedDate);
            const spanDate = addDaysGetSpan(new Date(), 1);
            setRangeSecondDatePre(spanDate);
            const formattedSecondDate = getFormattedDate(spanDate, formatDate, signDate)
            setRangeSecondDate(formattedSecondDate);
        }
    }, [showDateCal])

    useEffect(() => {
        const classList = getClassAtt
        let studentData =
            classList &&
            classList.map(student => {
                let studentValue = { ...student }
                studentValue = { ...studentValue, editOpen: false }
                return studentValue
            })
        setDefaultList(studentData)
        setGetClassAttStudentList(studentData)
    }, [props])

    const setDate = (date) => {
        setAddDate(date);
    }
    const setRangeFirstDate = (date) => {
        setFirstDatePre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setFirstDate(formattedDate);
    }

    const addRangeSecondDate = (date) => {
        setRangeSecondDatePre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setRangeSecondDate(formattedDate);
    }

    const addWeekFirstDate = (date) => {
        if (showDateCal === "This Week") {
            setWeekFirstDatePre(date);
            const formattedDate = getFormattedDate(date, formatDate, signDate);
            setWeekFirstDate(formattedDate);
            const spanDate = addDaysGetSpan(date, 7);
            setWeekSecondDatePre(spanDate);
            const secondWeekDate = getFormattedDate(spanDate, formatDate, signDate)
            setWeekSecondDate(secondWeekDate);
        }
    }

    const addMonthFirstDate = (date) => {
        if (showDateCal === "This Month") {
            setMonthFirstDatePre(date);
            const formattedDate = getFormattedDate(date, formatDate, signDate);
            setMonthFirstDate(formattedDate);
            const spanDate = addDaysGetSpan(date, 30);
            setMonthSecondDatePre(spanDate);
            const secondMonthDate = getFormattedDate(spanDate, formatDate, signDate)
            setMonthSecondDate(secondMonthDate);
        }

        if (showDateCal === "This Year") {
            setYearFirstDatePre(date);
            const formattedDate = getFormattedDate(date, formatDate, signDate);
            setYearFirstDate(formattedDate);
            const spanDate = addDaysGetSpan(date, 365);
            setYearSecondDatePre(spanDate);
            const secondMonthDate = getFormattedDate(spanDate, formatDate, signDate)
            setYearSecondDate(secondMonthDate);
        }
    }
    // const [openReportComponent, setopenReportComponent] = useState(false)
const confirmationViewReport = () => {
    setShowParticularAtt(true);
    setShowViewAtt(false);

    setIsFirstOpen(false);

    if (showDateCal === "Single Day"
        || showDateCal === "Today") {
            setopenReportComponent(true)
        if (showViewAtt) {
            const formatDate = (dateString) => {
                const [month, day, year] = dateString.split("/");
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
              };
              
              const formattedDate = formatDate(updatedDateTwo);
            const data = {
                date: formattedDate || getDateFormatAsApi(addDate),
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
            }
            sessionStorage.setItem("reportobj",JSON.stringify(data))
            getClassAttList(data)
        }
    }

    if (showDateCal === "Date Range") {
        setShowRangeAtt(true);
        if (showViewAtt) {
            const data = {
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
                startDate: getDateFormatAsApi(firstDatePre),
                endDate: getDateFormatAsApi(rangeSecondDatePre)
            }
            getClassAttByDateRange(data)
        }
    }
    if (showDateCal === "This Week") {
        setShowRangeAtt(true);

        if (showViewAtt) {
            const data = {
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
                startDate: getDateFormatAsApi(weekFirstDatePre),
                endDate: getDateFormatAsApi(weekSecondDatePre)
            }
            getClassAttByDateRange(data)
        }
    }
    if (showDateCal === "This Month") {
        setShowRangeAtt(true);

        if (showViewAtt) {
            const data = {
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
                startDate: getDateFormatAsApi(monthFirstDatePre),
                endDate: getDateFormatAsApi(monthSecondDatePre)
            }
            getClassAttByDateRange(data)
        }
    }
    if (showDateCal === "This Year") {
        setShowRangeAtt(true);
        if (showViewAtt) {
            const data = {
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
                startDate: getDateFormatAsApi(yearFirstDatePre),
                endDate: getDateFormatAsApi(yearSecondDatePre)
            }
            getClassAttByDateRange(data)
        }
    }
}
    // const openPopOver = (userId, item) => {
    //     setCommentData(
    //         item.cc_remarks === 'null' ? '' : item.cc_remarks
    //     )
    //     setOpenUserId(userId)
    //     if (!isOpen) {
    //         let studentData =
    //             getClassAttStudentList &&
    //             getClassAttStudentList.map(student => {
    //                 let studentValue = { ...student }
    //                 if (studentValue.cc_student_id === userId) {
    //                     studentValue = { ...studentValue, editOpen: true }
    //                     return studentValue
    //                 } else {
    //                     return { ...studentValue }
    //                 }
    //             })
    //         setGetClassAttStudentList(studentData)
    //         setIsOpen(true)
    //     }
    // }

    // const closePopOver = (userId, indexId) => {
    //     setCommentData('')
    //     setIsEdited(false)
    //     setOpenUserId('')
    //     let studentListData =
    //         getClassAttStudentList &&
    //         getClassAttStudentList.map(student => {
    //             let studentValue = { ...student }
    //             if (studentValue.cc_student_id === userId) {
    //                 studentValue = {
    //                     ...studentValue,
    //                     editOpen: false,
    //                     cc_remarks: defaultList[indexId].cc_remarks
    //                 }
    //                 return studentValue
    //             } else {
    //                 return { ...studentValue }
    //             }
    //         })
    //     setGetClassAttStudentList(studentListData)
    //     setIsOpen(false)
    // }

    const setAttRemarks = (data, index) => {
        if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') {
            return;
        } else {
            let list = getClassAttStudentList &&
                getClassAttStudentList.map((user, id) => {
                    let userData = { ...user }
                    if (id === index) {
                        if (user.cc_attendance_status !== data) {
                            return { ...user, cc_attendance_status: data }
                        }
                    }
                    return userData
                })
            setGetClassAttStudentList(list)
        }
    }

    const disAttRemarks = (data, index) => { }
    const BackTOHome = () => {
        if (isEdited === true) {
            setShowConfirm(true);
            setByBack('1')
        }
        else {
            history.goBack()
            setShowParticularAtt(false);
            setShowRangeAtt(false);
            setEarlierDate("");
        }

    }
    const goToHome = () => {
        if (isEdited === true) {
            setShowConfirm(true);
            setByBack('2')
        }
        else {
            history.replace("/home/academic");
        }
    }
    const BackTOAtt = () => {
        if (isEdited === true) {
            setShowConfirm(true);
            setByBack('3')
        }
        else {
            getRefreshAtt();
            setShowDateCal("Single Day");
            setShowParticularAtt(false);
            setShowRangeAtt(false);
            setShowViewAtt(false);
            setAddDate(new Date());
            setGetClassAttStudentList(getClassAttStudentList);
            // sessionStorage.removeItem("updatedDate")
            const data = {
                date: sessionStorage.getItem("updatedDate") || getDateFormatAsApiforC(addDate),
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
              }
              getClassAttList(data)
        }
    }
    const goBack = () => {
        setShowConfirm(false)
        setIsEdited(false);
        if (byBack === '1') {
            history.goBack()
            setShowParticularAtt(false);
            setShowRangeAtt(false);
            setEarlierDate("");
        }
        else if (byBack === '2') {
            history.replace("/home/academic");
        }
        else if (byBack === '3') {
            getRefreshAtt();
            setShowDateCal("Single Day");
            setShowParticularAtt(false);
            setShowRangeAtt(false);
            setShowViewAtt(false);
            setAddDate(new Date());
            setGetClassAttStudentList(getClassAttStudentList);
        }
    }

    const getRefreshAtt = () => {
        const data = {
            date: getDateFormatAsApi(new Date()),
            class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail
        }
        getClassAttList(data)
    }

    // const saveGrade = userId => {
    //     let studentListData =
    //         getClassAttStudentList &&
    //         getClassAttStudentList.map(student => {
    //             let studentValue = { ...student }
    //             if (studentValue.cc_student_id === userId) {
    //                 studentValue = { ...studentValue, editOpen: false }
    //                 return studentValue
    //             } else {
    //                 return { ...studentValue }
    //             }
    //         })
    //     setOpenUserId('')
    //     setIsEdited(false)
    //     setGetClassAttStudentList(studentListData)
    //     setIsOpen(false)
    // }
    const changeUpdate = (e, item, id) => {
        setCommentData(e.target.value)
        let studentAttListData =
            getClassAttStudentList &&
            getClassAttStudentList.map(student => {
                let studentValue = { ...student }
                if (studentValue.cc_student_id === id) {
                    studentValue = { ...studentValue, cc_remarks: e.target.value }
                    return studentValue

                } else {
                    return { ...studentValue }
                }
            })
        setGetClassAttStudentList(studentAttListData)
    }

    const unSelectWhenClose = () => {
        setGetClassAttStudentList(defaultList)
        setIsEdited(false)
        setIsOpen(false)
    }

    const getDateFormatAsApi = date => {
        const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = offsetDate.toISOString().split('T')[0];
        return formattedDate;
      }

    const viewAttendanceClick = date => {
        const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
        setUpdatedDateTwo(formattedDate)
        setShowViewAtt(true)
    }

    const cancelViewAtt = date => {
        setShowViewAtt(false);
        setAddDate(new Date());
        setShowDateCal("Single Day");
    }

    const setDateSpan = SpanFormat => {
        setShowDateCal(SpanFormat)
    }

    const viewPdfModal = () => {
        setShowPdfReport(true)
    }
    const pdfReportView = () => {
        setShowPdfReport(false);
        setShowPreview(true);
        setShowPreviewType("pdf");
        if (showDateCal === "Single Day"
            || showDateCal === "Today") {
            const reportDate = JSON.parse(sessionStorage.getItem("reportobj"))?.date
            const rangeStartDate = reportDate || getDateFormatAsApi(addDate);
            const rangeEndDate = reportDate || getDateFormatAsApi(addDate);
            const pdfUrl = `${base_Microservice_url}student/` + `api_test_download_pdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            singlePDFDownload(pdfUrl, `${"attendanceReport.pdf"}`);
        }

        if (showDateCal === "Date Range") {
            const rangeStartDate = getDateFormatAsApi(firstDatePre);
            const rangeEndDate = getDateFormatAsApi(rangeSecondDatePre);
            const pdfUrl = `${base_Microservice_url}student/` + `api_test_download_pdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            singlePDFDownload(pdfUrl, `${"attendanceReport.pdf"}`);
        }

        if (showDateCal === "This Week") {
            const rangeStartDate = getDateFormatAsApi(weekFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(weekSecondDatePre);
            const pdfUrl = `${base_Microservice_url}student/` + `api_test_download_pdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            singlePDFDownload(pdfUrl, `${"attendanceReport.pdf"}`);
        }


        if (showDateCal === "This Month") {
            const rangeStartDate = getDateFormatAsApi(monthFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(monthSecondDatePre);
            const pdfUrl = `${base_Microservice_url}student/` + `api_test_download_pdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            singlePDFDownload(pdfUrl, `${"attendanceReport.pdf"}`);
        }

        if (showDateCal === "This Year") {
            const rangeStartDate = getDateFormatAsApi(yearFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(yearSecondDatePre);
            const pdfUrl = `${base_Microservice_url}student/` + `api_test_download_pdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            singlePDFDownload(pdfUrl, `${"attendanceReport.pdf"}`);
        }
    }

    const csvReportView = () => {
        setShowPreview(true);
        setShowPdfReport(false)
        setShowPreviewType("csv")
        if (showDateCal === "Single Day"
            || showDateCal === "Today") {
                const reportDate = JSON.parse(sessionStorage.getItem("reportobj"))?.date
                const rangeStartDate = reportDate || getDateFormatAsApi(addDate);
                const rangeEndDate = reportDate || getDateFormatAsApi(addDate);
            const csvUrl = `${base_Microservice_url}student/` + `api_test_download_csv?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            setShowCsvUrl(csvUrl);
        }
        if (showDateCal === "Date Range") {
            const rangeStartDate = getDateFormatAsApi(firstDatePre);
            const rangeEndDate = getDateFormatAsApi(rangeSecondDatePre);
            const csvUrl = `${base_Microservice_url}student/` + `api_test_download_csv?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            setShowCsvUrl(csvUrl);
        }

        if (showDateCal === "This Week") {
            const rangeStartDate = getDateFormatAsApi(weekFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(weekSecondDatePre);
            const csvUrl = `${base_Microservice_url}student/` + `api_test_download_csv?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            setShowCsvUrl(csvUrl);
        }

        if (showDateCal === "This Month") {
            const rangeStartDate = getDateFormatAsApi(monthFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(monthSecondDatePre);
            const csvUrl = `${base_Microservice_url}student/` + `api_test_download_csv?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            setShowCsvUrl(csvUrl);
        }

        if (showDateCal === "This Year") {
            const rangeStartDate = getDateFormatAsApi(yearFirstDatePre);
            const rangeEndDate = getDateFormatAsApi(yearSecondDatePre);
            const csvUrl = `${base_Microservice_url}student/` + `api_test_download_csv?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail}`
            setShowCsvUrl(csvUrl);
        }
    }

    const saveAttData = () => {
        const attendanceData = []
        let attStatus = []
        getClassAttStudentList &&
            getClassAttStudentList.map(student => {
                let studentValue = { ...student }
                let studentList = {}
                if (studentValue.cc_student_id) {
                    studentList = {
                        ...studentList,
                        student_id: studentValue.cc_student_id,
                        attendance_status: studentValue.cc_attendance_status,
                        remarks: studentValue.cc_remarks === "null"
                            || studentValue.cc_remarks === null ? "" : studentValue.cc_remarks
                    }
                    attendanceData.push(studentList)
                }
                return attendanceData
            })

        attendanceData &&
            attendanceData.map(studentAttData => {
                attStatus.push(studentAttData.attendance_status)
                return attStatus
            })
        if (attStatus.includes(null)) {
            createNotification('error', 'Please fill in the Attendance for all students.')
        } else {
            const apiData = {
                student_attendance_array: attendanceData,
                date: sessionStorage.getItem("updatedDate") || getDateFormatAsApi( addDate),
                class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
                // class_id: classDetail.cc_class_id ? classDetail.cc_class_id  : classDetail
            }

            addClassAtt(apiData);
            setIsFirstOpen(true);
            setIsEdited(false)
            // if(isClasses) {
            // history.replace("/home/class");
            // } else {
            //     history.replace("/home/academic");
            // }
            // history.replace("/home/academic");
        }
        setIsEdited(false)
        // BackTOHome()
    }

    function blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    const singlePDFDownload = (URl, projectName) => {
        const options = {
            method: 'GET',
            baseURL: URl,
        };
        axios(options).then(async responses => {
            if (responses.data.message === "No record found") {
                setRecordFound(responses.data.message);
                createNotification('error', responses.data.message);
            } else {
                const options = {
                    method: 'GET',
                    baseURL: URl,
                    responseType: 'blob'
                };
                axios(options).then(async responses => {
                    const file = new Blob(
                        [responses.data],
                        { type: 'application/pdf' });
                    const myFile = blobToFile(file, "attendance.pdf");
                    setUpoadFile(myFile)
                    const fileURL = URL.createObjectURL(file);
                    setFileUrl(fileURL)
                })
            }
        })
            .catch(err => {
                if (err && err.message) {
                    createNotification('error', err.message);
                }
            });
    }

    const searchInput = useRef();
    useEffect(() => {
        if (classList) {
            const classDataList = classList
            const newClassDataList = classDataList?.map((obj, i) => (
                {
                    ...obj,
                    name: classDataList[i].cc_class_name,
                    value: classDataList[i].cc_class_id
                }

            ));
            const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id }));
            setNewArr(newArr)
        }
    }, [classList])

    const options = [
        {
            type: "group",
            name: "",
            items: classListData !== undefined ? classListData : newArr
        }
    ];

//  useEffect(() => {
 
//     return () => {
//       sessionStorage.removeItem("updatedDate")
//     }
//   }, [])
    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (...args) => {
        // const value = args[1].name
        // if(isEdited === true){
        //     setShowConfirm(true);
        //     setByBack('4')
        // }
        // else {
        //     // searchInput.current.querySelector("input").value = "";
        //     setIsSelected(false)
        //     setSelectedClassName(value)
        //     if (args.length > 0) {
        //         getAttendance(args[0])
        //     }
        // }

        // setIsSelected(false)
        changeSelected(false)
        setSelectedClassName(args[1].name)
        classNameChangeHandler(args[1].name)
        if (args.length > 0) {
            getAttendance(args[0])
        }
    };

    const getAttendance = (value) => {
        const data = {
            date: getDateFormatAsApi(addDate),
            class_id: value
        }
        getClassAttList(data)

    }

    const onValueChange = (event) => {
        setReportType(event)
    }

    const getReportByType = () => {
        if (reportType === "PDF") {
            pdfReportView()
        }
        else {
            csvReportView()
        }
    }
    const backtoattReport = () => {
        setopenReportComponent(false); 
        // sessionStorage.removeItem("updatedDate");
        const data = {
          date: sessionStorage.getItem("updatedDate") || getDateFormatAsApiforC(new Date()),
          class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail,
        }
        // sessionStorage.setItem("reportobj",JSON.stringify(data))
        getClassAttList(data)
      }
    const ClassText1 = isSelected ? selectedClassName2 : changeClassName;
    const ClassText = ClassText1?.replace(/(.{13})..+/, "$1…");
    // const ClassText = ClassText1;
    const getDateFormatAsApiforC = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      const calendarDateChange = (date) => {
        setDate(date)
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setUpdatedDate(formattedDate)
        sessionStorage.setItem("updatedDate" , getDateFormatAsApiforC(date) )
        const data = {
          date: getDateFormatAsApiforC(date),
          class_id: classId !== undefined ? classId : classDetail.cc_class_id !== undefined ? classDetail.cc_class_id : classDetail
        }
        getClassAttList(data)
      }
   const updatedDateObject = updatedDate ? new Date(updatedDate) : new Date();
    return (
        <div className={classAtteContainer.join(' ')}>
                  <>
          
                  {
                      openReportComponent  && !showPreview ?
                      <>
                      <Report {...props} 
                      classDetail = {classDetail}
                      history={history} 
                      getClassAttStudentList ={getClassAttStudentList} 
                      _ = {_}
                      showParticularAtt={showParticularAtt}
                      showRangeAtt={showRangeAtt}
                      bestAtt={bestAtt}
                      disAttRemarks={disAttRemarks}
                      disBest={disBest}
                      setAttRemarks={setAttRemarks}
                      goodAtt={goodAtt}
                      disGood={disGood}
                      fairAtt={fairAtt}
                      disFair={disFair}
                      noneAtt={noneAtt}
                      disNoneAtt={disNoneAtt}
                      emojiDivClickNone={emojiDivClickNone}
                      emojiDivFairClickNone={emojiDivFairClickNone}
                      viewPdfModal={viewPdfModal}
                      setShowPdfReport={setShowPdfReport}
                      showPdfReport={showPdfReport}
                      showDateCal={showDateCal}
                      setShowDateCal={setShowDateCal}
                      weekFirstDate={weekFirstDate}
                      weekSecondDate= {weekSecondDate}
                      weekFirstDatePre={weekFirstDatePre}
                      weekSecondDatePre={weekSecondDatePre}
                      monthFirstDate={monthFirstDate}
                      monthSecondDate={monthSecondDate}
                      yearFirstDate={yearFirstDate}
                      yearSecondDate={yearSecondDate}
                      firstDate={firstDate}
                      rangeSecondDate={rangeSecondDate}
                      updatedDate={updatedDate}
                      updatedDateTwo={updatedDateTwo}
                      addDate={addDate}
                      openReportComponent={openReportComponent}
                      setopenReportComponent= {setopenReportComponent}
                      setShowRangeAtt={setShowRangeAtt}
                      BackTOAtt={BackTOAtt}
                      BackTOAtt2={() => backtoattReport()}
                      academicYear={academicYear}
                      />
                          </>

                        //    const [weekFirstDate, setWeekFirstDate] = useState("");
    // const [weekSecondDate, setWeekSecondDate] = useState("");
    // const [weekFirstDatePre, setWeekFirstDatePre] = useState("");
    // const [weekSecondDatePre, setWeekSecondDatePre] = useState("");
                      :
                      <>

{!showPreview && <div>
    {!showParticularAtt && !showRangeAtt && (
        <div style={{ position: 'sticky', zIndex: 2, top: 0, background: '#f0f0f0', height: 85 }}>
            <div className='ClassAttendanceMainDiv'>
                <div className='classAttendaceDetailsMain'>
                    <div className='attClassBackBtnDiv'>
                        <img className='calIcon' src={back} alt='' width='60' height='40' onClick={goToHome} />
                    </div>
                    <div className='attClassDetailTextDiv'>
                        <div className='displayFlexClassRoster'>
                            <p className='attAcademicYearText' onClick={goToHome}>{academicYear}</p>
                            <i className="classForIcon material-icons">chevron_right</i>
                            {isClasses &&
                                <>
                                    <p className='attAcademicYearText' onClick={BackTOHome}>Class</p>
                                    <i className="classForIcon material-icons">chevron_right</i>
                                </>
                            }
                            {byAttendanceBtn === true ?
                                <p className='attText'>Attendance </p>
                                :

                                <p className='attText'>Attendance: {' ' + ClassText} </p>

                            }

                        </div>
                    </div>
                </div>
                <div></div>
                {byAttendanceBtn === true ?
                    <div className='dropdownDiv'>
                        <div className="dropdown-main-container2">
                            <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                            <SelectSearch
                                ref={searchInput}
                                options={options}
                                filterOptions={handleFilter}
                                value={isSelected ? { name: selectedClassName2, value: 0 } : { name: changeClassName, value: 0 }}
                                name="Workshop"
                                placeholder="Search class*"
                                search
                                onChange={handleChange}
                                disabled={isEdited}
                            />
                        </div>
                        <div className='attClassDetailTextDiv2'>
                            <div className='displayFlexClassRoster ' style={{alignItems : "baseline"}}>
                                {/* <img className='calIcon' src={calendarIcon} alt='' width='18' height='18' /> */}
                                <DatePicker 
                        onChange={(date) => { calendarDateChange(date) }}
                        customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                        minDate={addYears(new Date(), -1)} 
                        maxDate={new Date()}
                        selected = {updatedDateObject}
                    />
                                <p className='attClassDetailTextDiv2 attClassGrades' style={{ fontSize: '14px' }}> 
                                    {sessionStorage.getItem("updatedDate") || updatedDate} </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='updateDateDiv'>
                        {/* <img src={calendarIcon} alt='' width='18' height='18' /> */}
                        <DatePicker 
         onChange={(date) => { calendarDateChange(date) }}
           customInput={<img className='new' style={{cursor : "pointer"}}  src={calendarIcon} alt="" width="18" height="18" />}
                        minDate={addYears(new Date(), -1)} 
                        maxDate={new Date()}
                        // selected = {updatedDateObject}
                    />
                        <p style={{ marginBottom: '0px', marginLeft: '5px', fontSize: '14px' }} className='attClassGrades'>
                        {sessionStorage.getItem("updatedDate") || updatedDate}
                        </p>
                    </div>
                }
                <div className='buttonMarginTop'>
                    <div className='displayFlexInSeletAtt' style={{ paddingRight: '1px' }}>
                        <Button style= {!isEdited ? {cursor  : "not-allowed", opacity: isEdited ? '1' : '0.50', background: '#ea3737'} : {cursor : "pointer" , opacity: isEdited ? '1' : '0.50', background: '#ea3737'}} className='cancleBtn' disabled={!isEdited} onClick={() => {history.goBack()}}>
                            <img style={{ width: '20px', height: '20px', }} src={crossMark} alt='' />
                            <p className='cancleBtnText'>Cancel</p>
                        </Button>
                        <Button style= {!isEdited ? {cursor  : "not-allowed", opacity: isEdited ? '1' : '0.50', background: '#23BF1D'} : {cursor : "pointer" , opacity: isEdited ? '1' : '0.50', background: '#23BF1D'}} className='saveBtnDiv' disabled={!isEdited} onClick={saveAttData}>
                            <i style={{ marginTop: '-7px' }} className='confirmIcon material-icons'> check </i>
                            <p className='saveBtnText' style={{ marginLeft: '-10px' }}>Save</p>
                        </Button>
                    </div>

                    <Button disabled={getClassAttStudentList?.length < 1} className='reportBtnDiv' onClick={viewAttendanceClick} >
                        <img className='reportIcon' src={eye} alt='' />
                        <span className='reportText'> Report </span>
                    </Button>
                </div>
            </div>
        </div>
    )}
    {showParticularAtt || showRangeAtt ? (
        <div className='viewAttTitleDiv'>
            <div className='classAttendaceDetailsMain'>
                <div className='backBttn qqq' style={{ width: '40px', height: '40px', borderRadius: '0.5rem', alignItems: 'center', display: 'flex', marginLeft: '10px', justifyContent: 'center' }} 
                // onClick={goToHome}
                onClick={() => BackTOAtt()}
                
                >
                    <i className='attClassBackIcon material-icons' style={{ color: '#ffffff', fontSize: '30px', marginBottom: '3px' }} >chevron_left </i>
                </div>
            </div>
            <div className='attClassDetailTextDiv'>
                <div className='firstDetailsDiv'>
                    <div className='headingTitles'>

                        <p className='attTitleName'> School: </p>
                        <p className='head'>{userSchoolName}</p>
                    </div>
                    <div className='headingTitles'>
                        <p className='attTitleName'> Class: </p>
                        <p className='head'>{ClassText1.replace(/(.{13})..+/, "$1…")}</p>
                    </div>
                </div>
            </div>
            <div className='attClassDetailTextDivTwo'>
                <div className='firstDetailsDivatt'>
                    <div className='headingTitles'>

                        <p className='attTitleName'> Teacher: </p>
                        <p className='head'>{fullTeacherName?.replace(/(.{13})..+/, "$1…") || ''}</p>
                    </div>
                    <div className='headingTitles'>
                        <p className='attTitleName'> Date: </p>
                        {showDateCal === "This Week" ? <p className='head'>{weekFirstDate} - {weekSecondDate}</p> : ""}
                        {showDateCal === "This Month" ? <p className='head'>{monthFirstDate} - {monthSecondDate}</p> : ""}
                        {showDateCal === "This Year" ? <p className='head'>{yearFirstDate} - {yearSecondDate}</p> : ""}
                        {showDateCal === "Date Range" ? <p className='head'>{firstDate} - {rangeSecondDate}</p> : ""}
                        {showDateCal === "Single Day" || showDateCal === "Today" ? <p className='head'>{updatedDate}</p> : ""}
                    </div>
                </div>
            </div>
            <div className='classOptionGet sss'
                onClick={viewPdfModal}
                style={{
                    opacity: (!getClassAttStudentList || !classAttByDateRangeList || getClassAttStudentList?.length == 0 || classAttByDateRangeList?.length == 0) ? '0.50' : '1',
                    pointerEvents: (!getClassAttStudentList || !classAttByDateRangeList || getClassAttStudentList?.length == 0 || classAttByDateRangeList?.length == 0) && 'none'
                }}
            >
                <img src={getReport} alt='' className='classOptionIcons' />
                <p style={{ color: 'white', fontSize: '0.7rem', fontWeight: '600', marginTop: '1rem' }}>Report</p>
            </div>
        </div>) : ""
    }
    {loader && (
        <div>
            <img src={loaderImag} alt='' className='loaderIconCss' />
        </div>
    )}
    {getClassAttStudentList && getClassAttStudentList.length < 1 ? (
        <div data-testid="noClassAttListPara">
            <p className='noneStudents'>  Please add students to the class to use attendance module.</p>
        </div>
    ) : (
        ''
    )}
    {!showPreview && !showRangeAtt && getClassAttStudentList &&
        getClassAttStudentList.map((item, index) => (
            <div className="classAttParentContainer" key={index}>
                <div className='classAttCardDiv'>
                    <div className='classAttDetailsCard'>
                        <div className='classAttProfileImageDiv'>
                            {item.cc_student_pic_url?.includes('https') ? (
                                <img src={item.cc_student_pic_url} alt='' className='studentAttProfileImage' />
                            ) : (
                                <div className='imageDummyDivProfileAtt'>
                                    <h5 className='profileImageAttDummyText'>
                                        {item.cc_student_first_name.charAt(0)}
                                        {item.cc_student_last_name.charAt(0)}
                                    </h5>
                                </div>
                            )}
                        </div>
                        <div className='classAttTextInfoDiv'>
                            <p style={item.cc_student_email_id == "" ? { paddingTop: "8px" } : { paddingTop: "0px" }} className='classAttName'>
                                {item.cc_student_first_name}, {item.cc_student_last_name}
                            </p>
                            <p className='classAttEmail'>{_.truncate(item.cc_student_email_id, {
                                'length': 30,
                                'omission': '...'
                            })}</p>
                        </div>

                        {!showParticularAtt && !showRangeAtt && (<div className='emojiDiv'>
                            <div className='emojiIcons'>

                                {item.cc_attendance_status === 1 ? (
                                    <img className='emojiIcons-img' src={bestAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(1, index) } }} alt='disEmoji' width='48' height='47' />

                                ) : (
                                    <img className='emojiIcons-img' src={disBest}
                                        onClick={() => { setAttRemarks(1, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 1 ?
                                        <p className='happyy'>Happy</p> : ""
                                }
                            </div>
                            <div className='emojiIcons'>

                                {item.cc_attendance_status === 2 ? (
                                    <img className='emojiIcons-img' src={goodAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(2, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disGood}
                                        onClick={() => { setAttRemarks(2, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 2 ?
                                        <p className='neutral'>Neutral</p> : ""
                                }
                            </div>
                            <div className='emojiIconsFair'>
                                {item.cc_attendance_status === 3 ? (
                                    <img className='emojiIcons-img' src={fairAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(3, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disFair}
                                        onClick={() => { setAttRemarks(3, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 3 ?
                                        <p className='sad'>Sad</p> : ""
                                }
                            </div>
                            <span className='vertical-line'> </span>
                            <div className='emojiIcons'>
                                {item.cc_attendance_status === 4 || '' ? (
                                    <img className='emojiIcons-img' src={noneAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(4, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disNoneAtt}
                                        onClick={() => { setAttRemarks(4, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 4 ?
                                        <p className='none'>Absent</p> : ""
                                }
                            </div>
                        </div>)}
                        {showParticularAtt && (<div className={'emojiDiv'}>
                            {item.cc_attendance_status === 1 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={bestAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 1 ?
                                        <p className='happyy'>Happy</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === 2 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={goodAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 2 ?
                                        <p className='neutral'>Neutral</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === 3 ? (<div className={emojiDivFairClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={fairAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 3 ?
                                        <p className='sad'>Sad</p> : ""
                                }
                            </div>
                            ) : ""}
                            {item.cc_attendance_status === 4 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={noneAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 4 ?
                                        <p className='none'>Absent</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === null ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={disNoneAtt} alt='disEmoji' width='65' height='65' />
                            </div>) : ""}
                        </div>)}
                    </div>

                </div>
                {!showParticularAtt && !showRangeAtt &&
                    <div className='commentCardDiv'>

                        <div className='textBoxBtnDiv'>
                            <Form.Control style={{ paddingTop: "12px", paddingLeft: "15px" }} onChange={e => { changeUpdate(e, index, item.cc_student_id) }} id={item.cc_student_id}
                                className='commentTextBox' as='textarea' rows='6' placeholder={'Enter Comments'} value={item.cc_remarks} disabled={classDetail?.cc_class_share_permission == 'read' || props.history.location.state?.selectedClassDetail?.cc_class_share_permission == 'read'} />

                        </div>
                    </div>
                }

                {showParticularAtt && !showRangeAtt && <div className="showParticularComtBox">
                    {item.cc_remarks === null || item.cc_remarks === "null" || item.cc_remarks === "" ?
                        <p className="showNonePartiCmtText">No Comments...</p> :
                        <p className="showPartiCmtText">{item.cc_remarks}</p>}
                </div>}
            </div>
        ))}
    {!showPreview && showRangeAtt && classAttByDateRangeList && classAttByDateRangeList.map((item, k) => (
        <div className="classAttRangeParentContainer" key={k}>
            <div className='classAttCardRangeDiv'>
                <div className='classAttDetailsCard'>
                    <div className='classAttProfileImageDiv'>
                        {item.cc_student_pic_url?.includes('https') ? (
                            <img src={item.cc_student_pic_url} alt='' className='studentAttProfileImage' />
                        ) : (
                            <div className='imageDummyDivProfileAtt'>
                                <h5 className='profileImageAttDummyText'>
                                    {item.cc_student_first_name.charAt(0)}
                                    {item.cc_student_last_name.charAt(0)}
                                </h5>
                            </div>
                        )}
                    </div>
                    <div className='classAttTextInfoDiv'>
                        <p style={item.cc_student_email_id == "" ? { paddingTop: "8px" } : { paddingTop: "0px" }} className='classAttName'>
                            {item.cc_student_last_name}, {item.cc_student_first_name}
                        </p>
                        <p className='classAttEmail'>
                            {_.truncate(item.cc_student_email_id, {
                                'length': 30,
                                'omission': '...'
                            })}
                        </p>
                    </div>
                    <div className='emojiCommentsDiv'>
                        <div className='commentDiv'>
                            <div className='commentData-style' >
                                <img className='attComment-img' src={blueComment} alt='' width='40' height='40' />
                                <p className="commentsDataRange">{item.remarks_count} <br /> comments</p>
                            </div>
                        </div>
                    </div>
                    <div className={'emojiRangeDiv'}>
                        <div className={emojiDivClickNone.join(' ')}>
                            <img className='emojiIcons-img' src={rangeHappy} alt='disEmoji' width='50' height='50' />
                            <p className="digitsRangeHappy">{item.happy_count}</p>
                        </div>
                        <div className={emojiDivFairClickNone.join(' ')}>
                            <img className='emojiIcons-img' src={rangeFair} alt='disEmoji' width='50' height='50' />
                            <p className="digitsRangeFair">{item.neutral_count}</p>
                        </div>
                        <div className={emojiDivClickNone.join(' ')}>
                            <img className='emojiIcons-img' src={rangeSad} alt='disEmoji' width='50' height='50' />
                            <p className="digitsRangeSad">{item.sad_count}</p>

                        </div>
                        <div className={emojiDivClickNone.join(' ')}>
                            <img className='emojiIcons-img' src={rangeAbsent} alt='disEmoji' width='50' height='50' />
                            <p className="digitsRangeAbsent">{item.absent_count}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="showAttScoreBox">
                <div>
                    <p className="showNonePartiCmtText">Total Present</p>
                    <p className="showScoreText">{item.present_count}/{item.total_count}</p>
                </div>
            </div>
        </div>
    ))}
    {
        !showPreview && showRangeAtt && !loader && (!getClassAttStudentList || getClassAttStudentList?.length === 0) && <p className='mt-5'>No data is recorded for the selected date range</p>
    }
      {
        !showPreview && showRangeAtt && !loader && (!classAttByDateRangeList || classAttByDateRangeList?.length === 0) && <p className='mt-5'>No data is recorded for the selected date range</p>
    }
</div>}
      

      </>
}
<Modal centered className="att-modal-warning br-8" show={showViewAtt} >
    <Modal.Header>
        <Modal.Title>Select Date </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className='filterMainDiv'>
            <div className={listAllClassAttSingle.join(' ')} onClick={() => { setDateSpan("Single Day") }}>
                <p className={dateFilterTextSingle.join(' ')}>Single Day</p>
            </div>
            <div className={myClassFilterAttRange.join(' ')} onClick={() => { setDateSpan("Date Range") }}>
                <p className={dateFilterTextRange.join(' ')}>Date Range</p>
            </div>
            <div className={myClassFilterAttToday.join(' ')} onClick={() => { setDateSpan("Today") }}>
                <p className={dateFilterTextToday.join(' ')}>Today</p>
            </div>
            <div className={myClassFilterAttWeek.join(' ')} onClick={() => { setDateSpan("This Week") }}>
                <p className={dateFilterTextWeek.join(' ')}>This Week</p>
            </div>
            <div className={myClassFilterAttMonth.join(' ')} onClick={() => { setDateSpan("This Month") }}>
                <p className={dateFilterTextMonth.join(' ')}>This Month</p>
            </div>
            <div className={myClassFilterAttYear.join(' ')} onClick={() => { setDateSpan("This Year") }}>
                <p className={dateFilterTextYear.join(' ')}>This Year</p>
            </div>
        </div>
    </Modal.Body>
    <div className="dateBlock">
        {showDateCal === "Single Day" ||
            showDateCal === "Today" ? <div className="dateSubBlock">
            <div className="dayDateTextDiv" ><p className="dayDateText">{updatedDateTwo}</p></div>
            <div className={displayFlexAttClassRoster.join(' ')}>
                {showDateCal === "Single Day" ?
                    <DatePicker
                        onChange={date => { setDate(date) }}
                        customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                    /> : ""}
                {showDateCal === "Today" ?
                    <img className='calIcon' src={calendarIcon} alt='' width='18' height='18' /> : " "}
            </div>
        </div> : ""}
        {showDateCal === "Date Range" ? <div>
            <div className="dateSubBlock">
                <div className="dayDateTextDiv" ><p className="dayDateText">{firstDate}</p></div>
                <DatePicker
                    onChange={date => { setRangeFirstDate(date) }}
                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                />
            </div>

            <div className="dateSubBlocks">
                <div className="dayDateTextDiv" ><p className="dayDateText">{rangeSecondDate}</p></div>
                <DatePicker
                    onChange={date => { addRangeSecondDate(date) }}
                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                />
            </div>
        </div> : ""}
        {showDateCal === "This Week" ? <div>
            <div className="dateSubBlock">
                <div className="dayDateTextDiv" ><p className="dayDateText">{weekFirstDate}</p></div>
                <DatePicker
                    onChange={date => { addWeekFirstDate(date) }}
                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                />
            </div>

            <div className="dateSubBlocks">
                <div className="dayDateTextDiv" ><p className="dayDateText">{weekSecondDate}</p></div>
                <img className='calIcon' src={calendarIcon} alt='' width='18' height='18' />
            </div>
        </div> : ""}
        {showDateCal === "This Month" ? <div>
            <div className="dateSubBlock">
                <div className="dayDateTextDiv" ><p className="dayDateText">{monthFirstDate}</p></div>
                <DatePicker
                    onChange={date => { addMonthFirstDate(date) }}
                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                />
            </div>

            <div className="dateSubBlocks">
                <div className="dayDateTextDiv" ><p className="dayDateText">{monthSecondDate}</p></div>
                <img className='calIcon' src={calendarIcon} alt='' width='18' height='18' />
            </div>
        </div> : ""}

        {showDateCal === "This Year" ? <div>
            <div className="dateSubBlock">
                <div className="dayDateTextDiv" ><p className="dayDateText">{yearFirstDate}</p></div>
                <DatePicker
                    onChange={date => { addMonthFirstDate(date) }}
                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                />
            </div>

            <div className="dateSubBlocks">
                <div className="dayDateTextDiv" ><p className="dayDateText">{yearSecondDate}</p></div>
                <img className='calIcon' src={calendarIcon} alt='' width='18' height='18' />
            </div>
        </div> : ""}
    </div>
    <Modal.Footer>
        <Button variant="default" className="cancelAtt-btn"
            onClick={cancelViewAtt}
        >
            Cancel
        </Button>
        <Button variant="default" className="getReport-btn"
            onClick={confirmationViewReport}
        >
            Create Report
        </Button>
    </Modal.Footer>
</Modal>

<Modal centered className="viewPdf-modal-warning br-8" show={showPdfReport} onHide={() => { setShowPdfReport(false) }}>
    <Modal.Header>
        <Modal.Title>Get Report</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="pdfBlock" style={{ border: reportType === "PDF" ? '1px solid #386CB5' : '1px solid #EEEEEE' }}>
            <input type="radio" value="PDF" checked={reportType === "PDF"} onChange={() => { onValueChange('PDF') }} />
            <img className='reportIcon' src={pdfView} alt='disEmoji' width='50' height='50' />
            <p className="reportName">PDF</p>
        </div>
        <div className="csvBlock" style={{ border: reportType === "CSV" ? '1px solid #386CB5' : '1px solid #EEEEEE' }}>
            <input type="radio" value="CSV" checked={reportType === "CSV"} onChange={() => { onValueChange('CSV') }} />
            <img className='reportIcon' src={csvView} alt='disEmoji' width='50' height='50' />
            <p className="reportName">CSV</p>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="default" className="getReportBtn" onClick={getReportByType} > Get Report </Button>
    </Modal.Footer>
</Modal>
{/* Confirmation Modal */}
<Modal centered className="att-modal-warning br-8" show={showConfirm} onHide={() => { setShowConfirm(false) }}>
    <Modal.Header>
        <Modal.Title>
            <div>
                <img style={{ width: '60px', height: '60px' }} src={warningIcon} alt='' width='18' height='18' />
                <p style={{ textAlign: 'center', paddingTop: '10px', fontSize: '24px', color: '#FF1F1F' }}>Wait!</p>
            </div>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div>
            <p>Do you want to go back, your data will be lost.</p>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="default" className="cancelAtt-btn"
            onClick={() => { setShowConfirm(false) }}
        >
            Cancel
        </Button>
        <Button variant="default" className="getReport-btn"
            onClick={goBack}
        >
            Go Back
        </Button>
    </Modal.Footer>
</Modal>
{/* Confirmation Modal */}

{showPreview && <PreviewAttReport
    showPreviewType={showPreviewType}
    fileUrl={fileUrl}
    showCsvUrl={showCsvUrl}
    history={history}
    sendEmailReport={sendEmailReport}
    setShowPreviewType={setShowPreviewType}
    setFileUrl={setFileUrl}
    setShowCsvUrl={setShowCsvUrl}
    setShowPreview={setShowPreview}
    upoadFile={upoadFile}
    recordFound={recordFound}
    setRecordFound={setRecordFound}
    setUpoadFile={setUpoadFile}
/>}
            </>
     
        </div>
    )
}

export default AddEditFirstClassAttendance