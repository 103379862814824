import React, { useEffect } from 'react'
import getReport from 'Assets/AttendanceModule/getReport.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Form from 'react-bootstrap/Form'

function Report(props) {
  const {classDetail , history ,getClassAttStudentList,_,showParticularAtt,showRangeAtt,bestAtt,disAttRemarks,disBest,setAttRemarks,goodAtt,disGood,fairAtt,disFair,noneAtt,disNoneAtt,emojiDivClickNone,emojiDivFairClickNone,changeUpdate, viewPdfModal , showPdfReport , setShowPdfReport , showDateCal,weekFirstDate, setShowDateCal,weekSecondDate,weekFirstDatePre,weekSecondDatePre , monthFirstDate, monthSecondDate,yearFirstDate,yearSecondDate,firstDate,rangeSecondDate,updatedDate , updatedDateTwo, addDate, openReportComponent, setopenReportComponent, setShowRangeAtt , BackTOAtt2 , academicYear} = props;
  const userData = JSON.parse(sessionStorage.getItem('UserData'));
  const userFirstName = userData?.cc_user_first_name;
  const userLastName = userData?.cc_user_last_name;
  const userSchoolName = userData?.cc_user_school_name;
  const fullTeacherName = userData?.cc_user_first_name + ' ' + userData?.cc_user_last_name
  const BackToHome = () => {
   history.go(-1)
  }
 const reportDate = JSON.parse(sessionStorage.getItem("reportobj"))?.date
  return (
    <div>
      <div className="viewAttTitleDiv">
        <div className="classAttendaceDetailsMain">
          <div
            className="backBttn"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "0.5rem",
              alignItems: "center",
              display: "flex",
              marginLeft: "10px",
              justifyContent: "center",
            }}
            // onClick={goToHome}
            onClick={BackTOAtt2}
          >
            <i
              className="attClassBackIcon material-icons"
              style={{
                color: "#ffffff",
                fontSize: "30px",
                marginBottom: "3px",
              }}
            >
              chevron_left{" "}
            </i>
          </div>
        </div>
        <div className="attClassDetailTextDiv">
          <div className="firstDetailsDiv">
            <div className="headingTitles">
              <p className="attTitleName"> School: </p>
              <p className="head">{userSchoolName}</p>
            </div>
            <div className="headingTitles">
              <p className="attTitleName"> Class: </p>
              <p className="head">
                {props?.history?.location?.state?.selectedClass?.[0]?.name.replace(
                  /(.{13})..+/,
                  "$1…"
                ) || classDetail?.cc_class_name.replace(/(.{13})..+/, "$1…")}
              </p>
            </div>
          </div>
        </div>
        <div className="attClassDetailTextDivTwo">
          <div className="firstDetailsDivatt">
            <div className="headingTitles">
              <p className="attTitleName"> Teacher: </p>
              <p className="head">
                {fullTeacherName?.replace(/(.{13})..+/, "$1…") || ""}
              </p>
            </div>
            <div className="headingTitles">
              <p className="attTitleName"> Date: </p>
              {showDateCal === "This Week" ? <p className='head'>{weekFirstDate} - {weekSecondDate}</p> : ""}
                  {showDateCal === "This Month" ? <p className='head'>{monthFirstDate} - {monthSecondDate}</p> : ""}
                  {showDateCal === "This Year" ? <p className='head'>{yearFirstDate} - {yearSecondDate}</p> : ""}
                  {showDateCal === "Date Range" ? <p className='head'>{firstDate} - {rangeSecondDate}</p> : ""}
                  {showDateCal === "Single Day" || showDateCal === "Today" ? <p className='head'>{reportDate || updatedDateTwo}</p> : ""}
            </div>
          </div>
        </div>
<div
    className="classOptionGet"
    onClick={() => {
        if (getClassAttStudentList && getClassAttStudentList.length > 0 &&
            !getClassAttStudentList.every(item => item.cc_attendance_status === null)) {
            viewPdfModal(); // Call the function only when data exists
        }
    }}
    style={{
        opacity: (getClassAttStudentList && getClassAttStudentList.length > 0 &&
            !getClassAttStudentList.every(item => item.cc_attendance_status === null)) ? 1 : 0.7,
        pointerEvents: (getClassAttStudentList && getClassAttStudentList.length > 0 &&
            !getClassAttStudentList.every(item => item.cc_attendance_status === null)) ? 'auto' : 'none'
    }}
>
    <img src={getReport} alt="" className="classOptionIcons" />
    <p
        style={{
            color: "white",
            fontSize: "0.7rem",
            fontWeight: "600",
            marginTop: "1rem",
        }}
    >
        Report
    </p>
</div>

      </div>

   {getClassAttStudentList && getClassAttStudentList.length > 0 && 
 getClassAttStudentList.every(item => item.cc_attendance_status === null) ? (
    <div className="noDataFound">
        <p>No data is recorded for the selected date.</p>
    </div>
) : (
     getClassAttStudentList?.map((item, index) => (
            <div className="classAttParentContainer" key={index}>
                <div className='classAttCardDiv'>
                    <div className='classAttDetailsCard'>
                        <div className='classAttProfileImageDiv'>
                            {item.cc_student_pic_url?.includes('https') ? (
                                <img src={item.cc_student_pic_url} alt='' className='studentAttProfileImage' />
                            ) : (
                                <div className='imageDummyDivProfileAtt'>
                                    <h5 className='profileImageAttDummyText'>
                                        {item.cc_student_first_name.charAt(0)}
                                        {item.cc_student_last_name.charAt(0)}
                                    </h5>
                                </div>
                            )}
                        </div>
                        <div className='classAttTextInfoDiv'>
                            <p style={item.cc_student_email_id == "" ? { paddingTop: "8px" } : { paddingTop: "0px" }} className='classAttName'>
                                {item.cc_student_first_name}, {item.cc_student_last_name}
                            </p>
                            <p className='classAttEmail'>{_.truncate(item.cc_student_email_id, {
                                'length': 30,
                                'omission': '...'
                            })}</p>
                        </div>

                        {!showParticularAtt && !showRangeAtt && (<div className='emojiDiv'>
                            <div className='emojiIcons'>

                                {item.cc_attendance_status === 1 ? (
                                    <img className='emojiIcons-img' src={bestAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(1, index) } }} alt='disEmoji' width='48' height='47' />

                                ) : (
                                    <img className='emojiIcons-img' src={disBest}
                                        onClick={() => { setAttRemarks(1, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 1 ?
                                        <p className='happyy'>Happy</p> : ""
                                }
                            </div>
                            <div className='emojiIcons'>

                                {item.cc_attendance_status === 2 ? (
                                    <img className='emojiIcons-img' src={goodAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(2, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disGood}
                                        onClick={() => { setAttRemarks(2, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 2 ?
                                        <p className='neutral'>Neutral</p> : ""
                                }
                            </div>
                            <div className='emojiIconsFair'>
                                {item.cc_attendance_status === 3 ? (
                                    <img className='emojiIcons-img' src={fairAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(3, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disFair}
                                        onClick={() => { setAttRemarks(3, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 3 ?
                                        <p className='sad'>Sad</p> : ""
                                }
                            </div>
                            <span className='vertical-line'> </span>
                            <div className='emojiIcons'>
                                {item.cc_attendance_status === 4 || '' ? (
                                    <img className='emojiIcons-img' src={noneAtt}
                                        onClick={() => { if (classDetail?.cc_class_share_permission == 'read' || props.history.location.state.selectedClassDetail?.cc_class_share_permission == 'read') { return; } else { disAttRemarks(4, index) } }} alt='disEmoji' width='48' height='47' />
                                ) : (
                                    <img className='emojiIcons-img' src={disNoneAtt}
                                        onClick={() => { setAttRemarks(4, index) }} alt='disEmoji' width='65' height='65' />
                                )}
                                {
                                    item.cc_attendance_status === 4 ?
                                        <p className='none'>Absent</p> : ""
                                }
                            </div>
                        </div>)}
                        {showParticularAtt && (<div className={'emojiDiv'}>
                            {item.cc_attendance_status === 1 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={bestAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 1 ?
                                        <p className='happyy'>Happy</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === 2 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={goodAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 2 ?
                                        <p className='neutral'>Neutral</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === 3 ? (<div className={emojiDivFairClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={fairAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 3 ?
                                        <p className='sad'>Sad</p> : ""
                                }
                            </div>
                            ) : ""}
                            {item.cc_attendance_status === 4 ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={noneAtt} alt='disEmoji' width='48' height='47' />
                                {
                                    item.cc_attendance_status === 4 ?
                                        <p className='none'>Absent</p> : ""
                                }
                            </div>) : ""}
                            {item.cc_attendance_status === null ? (<div className={emojiDivClickNone.join(' ')}>
                                <img className='emojiIcons-img' src={disNoneAtt} alt='disEmoji' width='65' height='65' />
                            </div>) : ""}
                        </div>)}
                    </div>

                </div>
                {!showParticularAtt && !showRangeAtt &&
                    <div className='commentCardDiv'>

                        <div className='textBoxBtnDiv'>
                            <Form.Control style={{ paddingTop: "12px", paddingLeft: "15px" }} onChange={e => { changeUpdate(e, index, item.cc_student_id) }} id={item.cc_student_id}
                                className='commentTextBox' as='textarea' rows='6' placeholder={'Enter Comments'} value={item.cc_remarks} disabled={classDetail?.cc_class_share_permission == 'read' || props.history.location.state?.selectedClassDetail?.cc_class_share_permission == 'read'} />

                        </div>
                    </div>
                }

                {showParticularAtt && !showRangeAtt && <div className="showParticularComtBox">
                    {item.cc_remarks === null || item.cc_remarks === "null" || item.cc_remarks === "" ?
                        <p className="showNonePartiCmtText">No Comments...</p> :
                        <p className="showPartiCmtText">{item.cc_remarks}</p>}
                </div>}
            </div>
        )) )}
    </div>
  );
}

export default Report