import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import Premium from "Assets/DataUsageModule/Premium.png";
import personalSmall from "Assets/SubscriptionsModule/personalSmall.png";
import professionalSmall from "Assets/SubscriptionsModule/professionalSmall.png";
import premiumSmall from "Assets/SubscriptionsModule/premiumSmall.png";
import personalPath from "Assets/SubscriptionsModule/personalPath.svg";
import premiumPath from "Assets/SubscriptionsModule/premiumPath.png";
import * as routes from "Router/RoutesURL";
import Professional from "Assets/DataUsageModule/Professional.png";
import "../UpgradePlanDashboardComponent/UpgradePlanDashboard.scss";
import { Button } from "@mui/material";
import axios from "axios";
import { createNotification } from "Config/notificationtoast";

const UpgradePlan = (props) => {
  const {
    item,
    history,
    selectedPlan,
    addPaymentStatusSuccess,
    stripeMonthlyPriceObj,
    stripeYearlyPriceObj,
    userPaymentDetails,
    paypalMonthlyPlanObj,
    paypalYearlyPlanObj,
  } = props;

  const choosePlan = (item, value, price, stripeObj, paypalObj) => {
    var selectedPlanPrice = selectedPlan?.subscription_price
      .split("/")[0]
      .split("$ ")[1];
    if (selectedPlanPrice !== price.toString()) {
      // console.log(26, item)
      let obj = {
        ...item,
        selectedSubType: value,
        stripeObj,
        paypalObj,
        userPaymentDetails
      };
      // console.log(32, obj);
      sessionStorage.setItem("paymentObj", JSON.stringify(obj));
      addPaymentStatusSuccess();
      history.push(routes.PAYPAL, {
        item: JSON.parse(sessionStorage.getItem("paymentObj")),
      });
    }
  };

  useEffect(() => {
  //   const selctedPrice = ["planPrice", "selectedPackage", "freeText"];
  //   if(selectedPlan) {
  //   const upgradePlanButton = [
  //     "upgradePlanButton",
  //     selectedPlan?.subscription_sub_title === "ANNUALLY" &&
  //     selectedPlan?.subscription_title.toLowerCase() ===
  //       item?.cc_subscription_title.toLowerCase()
  //       ? "opacityProp"
  //       : "",
  //   ];
  //   const annualPlanPrice = [
  //     "annualPlanPrice text-center",
  //     selectedPlan?.subscription_sub_title === "MONTHLY" &&
  //     selectedPlan?.subscription_title.toLowerCase() ===
  //       item?.cc_subscription_title.toLowerCase()
  //       ? "opacityProp"
  //       : "",
  //   ];
  // }
  },[])

  const selctedPrice = ["planPrice", "selectedPackage", "freeText"];
  let upgradePlanButton = []
  let annualPlanPrice = []
  if(selectedPlan) {
   upgradePlanButton = [
    "upgradePlanButton",
    selectedPlan?.subscription_sub_title === "ANNUALLY" &&
    selectedPlan?.subscription_title.toLowerCase() ===
      item?.cc_subscription_title.toLowerCase()
      ? "opacityProp"
      : "",
  ];
   annualPlanPrice = [
    "annualPlanPrice text-center",
    selectedPlan?.subscription_sub_title === "MONTHLY" &&
    selectedPlan?.subscription_title.toLowerCase() ===
      item?.cc_subscription_title.toLowerCase()
      ? "opacityProp"
      : "",
  ];
}

  // const selctedPrice = ["planPrice", "selectedPackage", "freeText"];
  // const upgradePlanButton = [
  //   "upgradePlanButton",
  //   selectedPlan?.subscription_sub_title === "ANNUALLY" &&
  //   selectedPlan?.subscription_title.toLowerCase() ===
  //     item?.cc_subscription_title.toLowerCase()
  //     ? "opacityProp"
  //     : "",
  // ];
  // const annualPlanPrice = [
  //   "annualPlanPrice text-center",
  //   selectedPlan?.subscription_sub_title === "MONTHLY" &&
  //   selectedPlan?.subscription_title.toLowerCase() ===
  //     item?.cc_subscription_title.toLowerCase()
  //     ? "opacityProp"
  //     : "",
  // ];

  let planContent = item?.cc_subscription_content?.split("\\n");

  return (
    <Col md={4} lg={4} xs={12} sm={12} className="cardRowPadd0">
      <Row
        className={`cardPlanRow pt-0 pb-0 pl-0 pr-0 flex-column ${
          item?.cc_is_recommended === 1 && "premiumCardBoxShadow"
        }`}
      >
        <Col
          className="planIconBg customAllPadding"
          style={{
            position: item?.cc_is_recommended === 1 && "relative",
          }}
        >
          <div className="planDetails w-100 h-100 align-items-center justify-content-center flex-column">
            <img src={item?.plan_logo} alt="" width={90} />
            <p
              className="mt-3"
              style={{ color: item?.color_code, fontSize: 14, fontWeight: 600 }}
            >
              {item?.cc_subscription_title?.toUpperCase()}
            </p>
            {
              item?.cc_is_recommended == 1 && <div className="recommendBox">Recommended</div>
            }
            
            {/* {item.cc_subscription_title === "Premium" && (
              <>
                <img src={professionalSmall} alt="" />
                <p className="mt-3 premiumPara">Premium</p>
                <div className="recommendBox">Recommended</div>
              </>
            )} */}
            {/* {item.cc_subscription_title === "Personal" && (
              <>
                <img src={personalSmall} alt="" />
                <p className="mt-3 personalPlanPara">Personal</p>
              </>
            )}
            
            {item.cc_subscription_title === "Professional" && (
              <>
                <img src={premiumSmall} alt="" />
                <p className="mt-3 professionalPara">Professional</p>
              </>
            )} */}
          </div>
        </Col>
        <div style={{ position: "relative", minHeight: 300 }}>
          <Col className="benefitsDiv customAllPadding">
            <div className="planDetailsPoint ml-5 planDetailsPointFont">
              <p className="mBot5 dataPlans">
                * {item?.cc_subscription_description}
              </p>
              {planContent?.map((planContentItem, idx) => {
                return (
                  <p className="mBot5 dataPlans" key={idx}>
                    {planContentItem}
                  </p>
                );
              })}
            </div>
            {/* {item.cc_subscription_title === "Personal" ? (
              <Button variant="contained" className="planFreeBtn mt-5">
                Free
              </Button>
            ) : (
              <Button variant="contained" className="planPremiumProBtn mt-5">
                {`$ ${}`}
              </Button>
            )} */}
          </Col>
          <img
            src={item?.plan_logo_bg || personalPath}
            alt="bgPath"
            className="img-fluid"
            style={{ position: "absolute", right: 0, bottom: 0 }}
          />

          <Col className="planTypeTextDiv customAllPadding">
            <Row
              className="justify-content-center"
              style={{
                height: item?.cc_subscription_title === "Personal" && 132,
              }}
            >
              <div>
                {item?.cc_subscription_price === 0 ? (
                  selectedPlan?.subscription_title === "PERSONAL" ? (
                    <Button
                      variant="contained"
                      className="planFreeBtn"
                      // onClick={() => {
                      //   choosePlan(item, "free", item.cc_subscription_price);
                      // }}
                      style={{
                        opacity: "0.5",
                        pointerEvents: "none",
                        cursor: "default",
                      }}
                    >
                      Free
                    </Button>
                  ) : (
                    //   <p
                    //     className={`${selctedPrice.join(" ")}`}
                    //     onClick={() => {
                    //       choosePlan(item, "free", item.cc_subscription_price);
                    //     }}
                    //   >
                    //     Free
                    //   </p>
                    <Button
                      variant="contained"
                      className="planFreeBtn"
                      // onClick={() => {
                      //   choosePlan(item, "free", item.cc_subscription_price);
                      // }}
                      style={{
                        opacity: "0.5",
                        pointerEvents: "none",
                        cursor: "default",
                      }}
                    >
                      Free
                    </Button>
                    //   <p
                    //     className="planPriceFree freeText"
                    //     onClick={() => {
                    //       choosePlan(item, "free", item.cc_subscription_price);
                    //     }}
                    //   >
                    //     Free
                    //   </p>
                  )
                ) : (
                  ""
                )}

                {
                  item?.cc_subscription_price != 0 ? (
                    selectedPlan?.subscription_sub_title === "MONTHLY" &&
                    selectedPlan?.subscription_title?.toLowerCase() ===
                      item?.cc_subscription_title?.toLowerCase() ? (
                      <div
                        onClick={() => {
                          choosePlan(
                            item,
                            "monthly",
                            item.cc_subscription_price,
                            stripeMonthlyPriceObj,
                            paypalMonthlyPlanObj
                          );
                        }}
                        // className="upgradePlanButton"
                        className={upgradePlanButton?.join(" ")}
                        style={{ opacity: "0.5" }}
                      >
                        <p className="planPrice selectedPackage">{`$ ${item.cc_subscription_price} / month`}</p>
                      </div>
                    ) : (
                      <div
                        // className={upgradePlanButton.join(" ")}
                        className="upgradePlanButton"
                        onClick={() => {
                          if (item?.product_ids) {
                            choosePlan(
                              item,
                              "monthly",
                              item.cc_subscription_price,
                              stripeMonthlyPriceObj,
                              paypalMonthlyPlanObj
                            );
                          } else {
                            createNotification(
                              "error",
                              "Product is not available"
                            );
                          }
                        }}
                      >
                        <p className="planPrice">{`$ ${item?.cc_subscription_price} / month`}</p>
                      </div>
                    )
                  ) : (
                    ""
                  )
                  // item.cc_subscription_title === "Premium" ? (
                  //   selectedPlan.subscription_sub_title === "MONTHLY" &&
                  //   selectedPlan.subscription_title.toLowerCase() ===
                  //     item.cc_subscription_title.toLowerCase() ? (
                  //     <div
                  //       onClick={() => {
                  //         choosePlan(item, "monthly", item.cc_subscription_price);
                  //       }}
                  //       className="upgradePlanButton"
                  //     >
                  //       <p className="planPrice selectedPackage">{`$ ${item.cc_subscription_price} / month`}</p>
                  //     </div>
                  //   ) : (
                  //     <div
                  //       className={upgradePlanButton.join(" ")}
                  //       onClick={() => {
                  //         choosePlan(item, "monthly", item.cc_subscription_price);
                  //       }}
                  //     >
                  //       <p className="planPrice">{`$ ${item.cc_subscription_price} / month`}</p>
                  //     </div>
                  //   )
                  // ) : (
                  //   <>
                  //     {item?.cc_subscription_title?.toLowerCase() != "personal" && (
                  //       <div
                  //         className={upgradePlanButton.join(" ")}
                  //         onClick={() => {
                  //           choosePlan(
                  //             item,
                  //             "monthly",
                  //             item.cc_subscription_price
                  //           );
                  //         }}
                  //       >
                  //         <p className="planPrice">{`$ ${item.cc_subscription_price} / month`}</p>
                  //       </div>
                  //     )}
                  //   </>
                  // )
                }

                {/* {item.cc_subscription_price !== 0 &&
                item.cc_subscription_title === "Professional" ? (
                  selectedPlan.subscription_sub_title === "MONTHLY" &&
                  selectedPlan.subscription_title.toLowerCase() ===
                    item.cc_subscription_title.toLowerCase() ? (
                    <div
                      onClick={() => {
                        choosePlan(item, "monthly", item.cc_subscription_price);
                      }}
                      className="upgradePlanButton"
                    >
                      <p className="planPrice selectedPackage">{`$ ${item.cc_subscription_price} / month`}</p>
                    </div>
                  ) : (
                    <div
                      className={upgradePlanButton.join(" ")}
                      onClick={() => {
                        choosePlan(item, "monthly", item.cc_subscription_price);
                      }}
                    >
                      <p className="planPrice">{`$ ${item.cc_subscription_price} / month`}</p>
                    </div>
                  )
                ) : (
                  ""
                )} */}
              </div>
            </Row>
            <div className="annualDiv d-flex flex-wrap align-item-center w-100 flex-column">
              {item?.cc_subscription_annual_subTitle && (
                <p className="annualPlanType text-center annualPlanTypeParaSpan" data-testid="annual-para">
                  {item?.cc_subscription_annual_subTitle} &{" "}
                  <span>
                    {item?.cc_subscription_annual_subTitle_discount?.slice(
                      1,
                      -1
                    )}
                  </span>
                </p>
              )}

              {
                item?.cc_subscription_annual_price ? (
                  selectedPlan?.subscription_sub_title === "ANNUALLY" &&
                  selectedPlan?.subscription_title?.toLowerCase() ===
                    item?.cc_subscription_title?.toLowerCase() ? (
                    <p
                      className={annualPlanPrice.join(" ")}
                      style={{ opacity: "0.5" }}
                    >{`$ ${item?.cc_subscription_annual_price} / year`}</p>
                  ) : (
                    <p
                      //  className={annualPlanPrice.join(" ")}
                      className="selectedAnnualPlanPrice text-center cursor-pointer"
                      onClick={() => {
                        if (item?.product_ids) {
                          choosePlan(
                            item,
                            "annual",
                            item?.cc_subscription_annual_price,
                            stripeYearlyPriceObj,
                            paypalYearlyPlanObj
                          );
                        } else {
                          createNotification(
                            "error",
                            "Product is not available"
                          );
                        }
                      }}
                    >{`$ ${item?.cc_subscription_annual_price} / year`}</p>
                  )
                ) : (
                  ""
                )
                // item.cc_subscription_title === "Premium" ? (
                //   selectedPlan.subscription_sub_title === "ANNUALLY" &&
                //   selectedPlan.subscription_title === "PREMIUM" ? (
                //     <p
                //       className="selectedAnnualPlanPrice text-center"
                //       onClick={() => {
                //         choosePlan(
                //           item,
                //           "annual",
                //           item.cc_subscription_annual_price
                //         );
                //       }}
                //     >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                //   ) : (
                //     <p
                //       className={annualPlanPrice.join(" ")}
                //       onClick={() => {
                //         choosePlan(
                //           item,
                //           "annual",
                //           item.cc_subscription_annual_price
                //         );
                //       }}
                //     >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                //   )
                // ) : (
                //   <>
                //       {item?.cc_subscription_title?.toLowerCase() != "personal" && (
                //         <p
                //         className={annualPlanPrice.join(" ")}
                //         onClick={() => {
                //           choosePlan(
                //             item,
                //             "annual",
                //             item.cc_subscription_annual_price
                //           );
                //         }}
                //       >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                //       )}
                //     </>
                // )
              }

              {/* {item.cc_subscription_annual_price &&
              item.cc_subscription_title === "Professional" ? (
                selectedPlan.subscription_sub_title === "ANNUALLY" &&
                selectedPlan.subscription_title === "PROFESSIONAL" ? (
                  <p
                    className="selectedAnnualPlanPrice text-center"
                    onClick={() => {
                      choosePlan(
                        item,
                        "annual",
                        item.cc_subscription_annual_price
                      );
                    }}
                  >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                ) : (
                  <p
                    className={annualPlanPrice.join(" ")}
                    onClick={() => {
                      choosePlan(
                        item,
                        "annual",
                        item.cc_subscription_annual_price
                      );
                    }}
                  >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                )
              ) : (
                ""
              )} */}
            </div>
          </Col>
        </div>
      </Row>
    </Col>
  );
};

// UpgradePlan.propTypes = {
//   addPaymentStatusSuccess: PropTypes.func,
//   selectedPlan: PropTypes.object,
//   history: PropTypes.object,
//   item: PropTypes.object,
// };

export default UpgradePlan;
